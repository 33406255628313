import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomMultilineTextField from "../../../common/dynamicInputs/CustomMultilineTextField";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import CustomBreadcrumbs from "../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import { getLovList } from "../../../store/slices/lovMasterData";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { textFieldWidth } from "../../../utils/constants";
import LableandValuesPreview from "../../../common/CommonPreview/LableandValuesPreview";
import DynamicBasicTable from "../../../common/dynamicbasicTable/DynamicBasicTable";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import EmailForm from "../../contractManagement/CostApproval/EmailForm/EmailForm";
import { useForm, FormProvider } from "react-hook-form";
import RichTextEditor from "react-rte";
import { getEmailTemplateList } from "../../../store/slices/EmailTemplateData";
import { ContractDetails } from "../PMCommonDetails/ContractDetails";
import { RequirementDetails } from "../PMCommonDetails/RequirementDetails";
import ScopeDefineService from "../../../store/performanceManagement/services/scopeDefine";
import { CommonSLATable } from "../PMCommonDetails/CommonSLATable";
import { PM_MeasuredIn } from "../../../config/config";
import { CreateEventLogSLA } from "../../../store/performanceManagement/slices/pmEventLog";

interface InitiateReviewProps {
    view: any;
    edit: any;
    isEditData: any;
    onClose: () => void;
}

export const InitiateReview: React.FC<InitiateReviewProps> = ({
    view,
    edit,
    isEditData,
    onClose,
}) => {
    const dispatch = useAppDispatch();
    const { themeMode } = useSelector((state: RootState) => state.menu);
    const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
    const currentDate = dayjs();
    const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
        (state: RootState) => state.loggerData
    );

    const [addForm, setAddForm] = useState<any>(false);
    const [EventData, setEventData] = useState<any[]>([]);
    const [EventSLAData, setEventSLAData] = useState<any[]>([]);
    const [RequirementData, setRequirementData] = useState<any>({});
    const [currentIndex, setCurrentIndex] = useState(0);
    const [emailtosend, setemailtosend] = useState<any>("Vignesh.w@zlendo.com")
    const [templateName, settemplateName] = useState<any>("PM - Notification to Responder")
    const [contractdetails, setcontractdetails] = useState<any>({});


    let EmailFormData: any = {
        email_template_id: null,
        email_content: RichTextEditor.createEmptyValue(),
        is_Msword: false,
        is_Pdf: false,
        emailFormat: "",
        notify_email_id: null,
        subject: "",
        to_email_id: null,
    };

    const emailDetailsFormMethods = useForm<any>({ defaultValues: EmailFormData, mode: "onChange" });
    const {
        handleSubmit: emailDetailFormMethodsHandleSubmit,
        formState: { errors: errorsEmailDetailFormMethodsform, isDirty: emailDetailsFormMethodsIsDirty },
        getValues: emailDetailsFormMethodsGetValues,
        setValue: emailDetailsFormMethodsSetValue,
        reset: emailDetailsReset,
    } = emailDetailsFormMethods;

    useEffect(() => {
        dispatch(getLovList({ Name: "" }));
        dispatch(getEmailTemplateList());
        fetchScope();
    }, []);

    useEffect(() => {
        if (isEditData && isEditData.ProjectId) {
            fetchScope();
        }
    }, [isEditData]);
    console.log(isEditData, "isEditData");
    

    const fetchScope = async () => {
        try {
            const res = await ScopeDefineService.getScopeByEventLogId({ id: isEditData.ProjectId });
            setEventData(res);
        } catch (error) {
            console.error("Error fetching scope data:", error);
        }
    };

    useEffect(() => {
        if (EventData) {
            const formData = mapFormData(EventData);
            setEventSLAData(formData.SLA_data);
            setRequirementData(formData);
            console.log(formData, "formData");
        }
    }, [EventData]);

    const mapFormData = (data: any) => ({
        clause_reference_no: data?.pmprClauses?.[0]?.clause_reference_no || '',
        clause_Header: data?.pmprClauses?.[0]?.clause_Header || '',
        WorkFlow: '',
        scope_Of_Target: data?.pmprClauses?.[0]?.pmprClauseTarget?.[0]?.pmprctScope?.scope_Of_Target || '',
        deliverables: data?.pmprClauses?.[0]?.pmprClauseTarget?.[0]?.pmprctScope?.deliverables || '',
        review_Trigger_Name: data?.pmprClauses?.[0]?.pmprClauseTarget?.[0]?.pmprctMonitorPeriods?.review_Trigger_Name || '',
        frequency_Name: data?.pmprClauses?.[0]?.pmprClauseTarget?.[0]?.pmprctMonitorPeriods?.frequency_Name || '',
        review_Start_Date: data?.pmprClauses?.[0]?.pmprClauseTarget?.[0]?.pmprctMonitorPeriods?.review_Start_Date || '',
        review_End_Date: data?.pmprClauses?.[0]?.pmprClauseTarget?.[0]?.pmprctMonitorPeriods?.review_End_Date || '',
        input_Dependency_Name: data?.pmprClauses?.[0]?.pmprClauseTarget?.[0]?.pmprctScope?.input_Dependency_Name || '',
        SLA_data: data?.pmprClauses?.[0]?.pmprClauseTarget?.[0]?.pmprctsla || [],
    });

    console.log(RequirementData, EventSLAData, "EventSLAData");



    const SLAColumns = [
        { Field: "sno", DisplayName: "S.No" },
        { Field: "sla", DisplayName: "SLA" },
        { Field: "measured_In_Name", DisplayName: "Measured In" },
        { Field: "severity_Of_SLA_Name", DisplayName: "Severity" },
        { Field: "target_Min_value", DisplayName: "Target Min" },
        { Field: "target_Max_value", DisplayName: "Target Max" },
        { Field: "remark", DisplayName: "Remarks" },
        { Field: "Actual", DisplayName: "Actual" },
        { Field: "tolerance_Level", DisplayName: "Tolerance Level" },

    ];

    const closeFormValue = () => {
        if (onClose) {
            onClose();
        }
    };

    const handleSlaFormSubmit = () => {
        const formData: any = {};

        formData.eventlog_Target_Id = isEditData?.ProjectId;
        formData.moduleName = "Initiate Review";
        formData.activity = "Save";
        formData.Workflow_InstanceId = isEditData?.Workflow_InstanceId;
        formData.Process_InstanceId = isEditData?.Id;
        formData.PR_ReviewId = isEditData?.ProjectId;
        formData.slaProcess = [];
        if (EventSLAData) {
            EventSLAData.forEach((item: any) =>
                formData.slaProcess.push({
                    eventlog_SLA_Id: 0,
                    sla: item.sla,
                    measured_In: item.measured_In,
                    target_Min: item.target_Min,
                    target_Max: item.target_Max,
                    tolerance_Level: item.tolerance_Level,
                    bestScore: item.bestScore,
                    severity_Of_SLA: item.severity_Of_SLA,
                    remark: item.remark,
                    isActive: item.isActive,
                    updatedBy: userLoginResponse?.UserName,
                    updatedOn: currentDate,
                    actual: "",
                    score: "",
                    deviation: "",
                }))
        }
        console.log(formData);
        dispatch(CreateEventLogSLA(formData));

    };

    const handleEmailFormSubmit = (fromDatas: any) => {
        handleSlaFormSubmit();
        const formData: any = fromDatas;
        console.log(formData);

    }

    const transformData = (data: any) => {
        return data.map((parameteritem: any, index: any) => {
            return {
                ...parameteritem,
                sno: index + 1,
                target_Max_value: parameteritem?.measured_In_Name == PM_MeasuredIn.Date ? dayjs(parameteritem?.target_Max).format("DD/MM/YYYY") : parameteritem?.target_Max,
                target_Min_value: parameteritem?.measured_In_Name == PM_MeasuredIn.Date ? dayjs(parameteritem?.target_Min).format("DD/MM/YYYY") : parameteritem?.target_Min
            };
        });
    };

    return (
        <>
            <Box>
                <Stack spacing={3}>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <CustomBreadcrumbs
                            list={[
                                { value: 0, name: "Pending Action" },
                                { value: 1, name: `Initiate Review` },
                            ]}
                            onchange={closeFormValue}
                        />

                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "left",
                        }}
                    >
                        <Typography variant="h6" color={"text.primary"}>
                            {`Initiate Review`}
                        </Typography>
                    </Box>


                    <Box sx={{ display: "flex", width: "100%" }}>

                        <Box sx={{ width: "100%" }}>
                            <Stack spacing={2}>

                                <ContractDetails data={contractdetails} />


                                <Divider sx={{ border: '1px solid', color: "#e0e0e3" }} />

                                <RequirementDetails data={RequirementData} />

                                <Divider sx={{ border: '1px solid', color: "#e0e0e3" }} />

                                <CommonSLATable HeaderTitle={"SLA To Monitor"} TableColumns={SLAColumns} Tabledata={transformData(EventSLAData)} />

                            </Stack>
                            <Box sx={{ display: "flex", gap: 2, justifyContent: "end", mt: 4 }}>

                                <Box>
                                    <CustomButton
                                        type="submit"
                                        variant="contained"
                                        name={"Notify To Responder"}
                                        // disabled={currentIndex === 0}
                                        onClick={() => { setAddForm(true); }}
                                    />
                                </Box>

                            </Box>
                        </Box>
                    </Box >
                </Stack >

            </Box >

            <CustomDialog
                show={addForm}
                onHide={() => setAddForm(false)}
                maxWidth={"md"}
                minHeight={"50vh"}
                header={`Send Email For Responder`}
                contentNode={
                    <>
                        <FormProvider {...emailDetailsFormMethods}>

                            <EmailForm
                                isDisableAttachment={true}
                                templateName={templateName}
                                to_emails={emailtosend}
                                onSubmit={(data) => {
                                    handleEmailFormSubmit(data);
                                    // handleSlaFormSubmit();
                                }}
                                onClose={() => {
                                    setAddForm(false)
                                }}
                            />
                        </FormProvider>
                    </>

                }
            />

        </>
    );
};
