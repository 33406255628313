import React, { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import PageMainHeading from "../../../common/CommonHeadings/PageMainHeading";
import AddContractTypeMaster from "./AddContractTypeMaster";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import { useSelector } from "react-redux";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import { getDepartmentHistory } from "../../../store/slices/departmentData";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { getContractType } from "../../../store/contractManagement/slices/templateData";
import {
  activeInactiveContractType,
  getContractTypeList,
  updateContractTypeDetailsAddAndEdit,
} from "../../../store/contractManagement/slices/contractMasterSlice";
import { getLovList } from "../../../store/slices/lovMasterData";
import { ACTIVE, INACTIVE } from "../../../utils/constants";
import { IMenu } from "../../../models/loggin.type";
import { RecordLockRelease } from "../../recordLock/CommonRecordLock";


const ContractTypeMaster: React.FC = () => {
  const [permissionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const [isAddNewClauseType, setIsAddNewClauseType] = useState<boolean>(false);
  const { menuData, userLoginResponse, currentPagePermissionData, user } = useSelector(
    (state: RootState) => state.loggerData
  );
  const [isEditData, setIsEditData] = useState<any>({});
  const [isDraftDate, setIsDraftDate] = useState<any>(null);
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useAppDispatchThunk();
  const [Option, setOption] = useState("");
  const [isDeleteClause, setIsDeleteClause] = useState(false);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const { recordLock, isRecordLockAdded, isRecordLockFetched } = useSelector((state: RootState) => state.recordLock);
  const [getContractTypeMaster, setContractMasterTypeMaster] = useState([]);
    const { handleRecordLockUpdate } = RecordLockRelease();
  

  const {
    contractTypeMasterData,
    isContractTypeMasterAdded,
    isContractTypeMasterUpdated,
    isContractTypeMasterDeleted,
    ToasterMsg,
  } = useSelector((state: any) => state.contractManagementReducer?.contractMasterData);
  useEffect(() => {
    if (contractTypeMasterData?.length > 0) {
      const updatedData = contractTypeMasterData.map((clause: any) => ({
        ...clause,
        isParent: clause.isParent ? "Yes" : "No",
        IsActive: clause.isActive,
      }));
      setContractMasterTypeMaster(updatedData);
    }
  }, [contractTypeMasterData]);
  let columns: any = {
    table_name: "Contract Type",
    isAction: true,
    columns: [
      {
        Field: "contractType_Name",
        FieldType: "string",
        DisplayName: "Contract Type",
      },
      {
        Field: "isParent",
        FieldType: "string",
        DisplayName: "Is This a Parent Contract?",
      },
      {
        Field: "IsActive",
        FieldType: "string",
        DisplayName: "Status",
      },
      {
        Field: "updated_By",
        FieldType: "string",
        DisplayName: "Modified By",
      },
    ],
  };

   const { currentpageTitleData } = useSelector(
          (state: RootState) => state.FormTitleMasterData
        );
        
  const handleApiCall = () => {
    dispatch(getContractTypeList());
  };
  useEffect(() => {
    handleApiCall();
  }, []);
  const [HeaderColumn, setHeaderColumn] = useState(columns);
  console.log(permissionPage,"permissionPage")

  useEffect(() => {
    if (currentPagePermissionData) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);
  const handleOpen = async (data: any, option: string) => {
    const updatedData = contractTypeMasterData?.find((i: any) => i.contractType_Id === data.contractType_Id);
    if (!updatedData) return;
    setOption(option);
    if (option === "delete") {
      setIsDeleteData(updatedData);
      setIsDeleteClause(true);
    } else if (option === "history") {
      setIsVersionHistory(true);
      setIsEditData(updatedData);
    } else if (option === "edit") {
      setIsEditData(updatedData);
      dispatch(updateLoader(true));
      const response = { updatedData: true };
      const Edit_data: any = response;
      const canEdit = true;
      if (Edit_data?.updatedData === true) {
        if (canEdit) {
          dispatch(updateLoader(false));
          setIsEdit(true);
        }
      } else {
        dispatch(updateLoader(false));
      }
    } else if (option === "view") {
      setIsEditData(updatedData);
      setIsEdit(true);
    } else {
      setIsEdit(true);
    }
  };
  const deleteClauseMaster = () => {
    const inactiveFormData = {
      contractType_Id: isDeleteData.contractType_Id,
      contractType_Name: isDeleteData.contractType_Name,
      isActive: isDeleteData?.isActive === ACTIVE ? false : true,
      isParent: isDeleteData?.isParent,
      updated_By: userLoginResponse?.UserName,
      // updated_On: new Date().toISOString(),
      approved_By: userLoginResponse?.UserName,
      approved_On: new Date().toISOString(),
      actionTaken: isDeleteData?.isActive === ACTIVE ? INACTIVE : ACTIVE,
      remarks: isDeleteData.remarks,
      moduleName: "Contract Type Master",
      activity: isDeleteData?.isActive === ACTIVE ? INACTIVE : ACTIVE,
      createdBy: user?.UserName,
    };
    dispatch(activeInactiveContractType(inactiveFormData));
  };
  useEffect(() => {
    const handleFunc = () => {
      if (isContractTypeMasterAdded || isContractTypeMasterUpdated || isContractTypeMasterDeleted) {
        dispatch(updateContractTypeDetailsAddAndEdit(false));
          handleRecordLockUpdate();
        
        dispatch(
          updateToaster({
            isToaster: true,
            toasterMsg: ToasterMsg?.Message,
            isTosterFailed: ToasterMsg?.Status === "false" ? true : false,
          })
        );
        handleApiCall();
        setIsDeleteClause(false);
      }
    };
    handleFunc();
  }, [isContractTypeMasterAdded, isContractTypeMasterUpdated, isContractTypeMasterDeleted]);

  return (
    <Box sx={{ width: "100%" }}>
      <Stack direction="column" spacing={2}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
          {/* <PageMainHeading title={"Contract Type"} /> */}
          <Typography variant="h6" color={"text.primary"}>
                            {" "}
                            {currentpageTitleData ? currentpageTitleData?.ViewTitle : currentPagePermissionData?.MenuName}
                            {/* Holiday Master */}
                        </Typography>        </Box>
        <Box sx={{ width: "100%" }}>
          <MaterialDynamicGrid
            data={HeaderColumn}
            rows={getContractTypeMaster}
            handleAction={handleOpen}
            handleAdd={() => setIsAddNewClauseType(true)}
            enableZoomIcon={false}
            showAdd={permissionPage?.IsAdd === 1}
            showDelete={permissionPage?.IsDelete === 1}
            showView={permissionPage?.IsView === 1}
            showEdit={permissionPage?.IsEdit === 1}
            showExports={permissionPage?.IsExport === 1}
            showhistory={false}
            userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
             isEnableRecordLock = {{ IdField : "contractType_Id" , data :  {   moduleName : currentPagePermissionData?.MenuName || "",
                              // FormID : FormIdConfig?.departmentFormId ,
                              MenuID : currentPagePermissionData?.MenuId,
                              IsCheckPending : false  }}}
            pageName="Contract Type"
          />
        </Box>
      </Stack>
      <CustomDialog
        show={isAddNewClauseType}
        onHide={() => {
          setIsAddNewClauseType(false);
          

        }}
        maxWidth={"md"}
        // header={"Add Contract Type"}

        header={`Add ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Contract Type"}`}

        contentNode={
          <>
            <AddContractTypeMaster
              isEdit={false}
              isView={false}
              onClose={() => {
                setIsAddNewClauseType(false);
              }}
            />
          </>
        }
      />
      <CustomDialog
        show={isEdit}
        onHide={() => {
          setIsEdit(false);
          handleRecordLockUpdate();
        }}
        maxWidth={"md"}
        header={Option === "view" ? `View ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Contract Type"}` : `Update ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Contract Type"}`}
        contentNode={
          <>
            <AddContractTypeMaster
              existingData={isEditData}
              isEdit={isEdit}
              isView={Option === "view"}
              onClose={() => {
                setIsEdit(false);
                handleRecordLockUpdate();
              }}
            />
          </>
        }
      />
      <ModalPopUp
        onDeleteMethod={() => deleteClauseMaster()}
        isDelete={true}
        onHide={() => setIsDeleteClause(false)}
        show={isDeleteClause}
        header={"Confirm"}
        text={`Are you sure you want to ${
          isDeleteData?.isActive === ACTIVE ? "deactivate" : "activate"
        } the selected Contract Type ?`}
        ActionName={isDeleteData?.isActive === ACTIVE ? INACTIVE : ACTIVE}
      />
    </Box>
  );
};

export default ContractTypeMaster;
