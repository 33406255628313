import React, { useEffect, useState } from "react";
import { Typography, Box, Grid, InputAdornment, IconButton } from "@mui/material";
import { RootState, useAppDispatchThunk } from "../../store/store";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { ACTIVE, INACTIVE, textFieldWidth } from "../../utils/constants";
import CustomTextField from "../../common/dynamicInputs/CustomTextField";
import CustomSelect from "../../common/dynamicInputs/CustomSelect";
import CustomButton from "../../common/dynamicButton/CustomButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { countryDropDown, stateDropDown } from "../../mockData/dropDownJson";
import { IUser, userFormData } from "../../models/user.type";
import { addUser, getUsersList, updateUserDetails } from "../../store/slices/userData";
import CustomToogleSwitch from "../../common/dynamicInputs/CustomToogleSwitch";
import ProgressBar from "../../common/progressBar/progressBar";
import Close from "@mui/icons-material/Close";
import { addDefaultValue, updateDefaultValue } from "../../store/slices/defaultValues";
import AddRole from "../rolesManagement/AddRole";
import { IPageAccessControl, IRole } from "../../models/roles.type";
import { FormIdConfig, LovConfig, ToasterActivityConfig, userConfig } from "../../config/config";
import { IMenu } from "../../models/loggin.type";
import { updateLoader } from "../../store/slices/loader";
import userListService from "../../store/services/usersListService";
import loginService from "../../store/services/loginService";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";

export interface userProps {
  view?: boolean;
  closePopUp?: () => void;
  edit?: boolean;
  userdata?: IUser | null | undefined;
  defaultdata?: any;
  isDefault?: boolean;
  defaultId?: number;
  //   isEditData?: any;
  //   edit?: boolean;
  //   // setIsEdit?:boolean;
  //   isEdited?: any;
  //   isAdded?:any;
}

const AddUser: React.FC<userProps> = ({
  view,
  closePopUp,
  edit,
  userdata,
  defaultdata = null,
  isDefault = false,
  defaultId,
}) => {
  const dispatch = useAppDispatchThunk();
  const { isUserAdded, roleMaster, isUserUpdated } = useSelector((state: RootState) => state.userData);
  const { isDefaultValuedAddedd, isDefaultValuedUpdated } = useSelector((state: RootState) => state.defaultData);
  const { allRoles } = useSelector((state: RootState) => state.roleData);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditRoleData, setisEditRoleData] = useState<IRole | null>(null);
  const [addedMenu, setAddedMenu] = useState<any[]>([]);
  const [existingMenu, setExistingMenu] = useState<any[]>([]);
  const [finalMenuData, setFinalMenuData] = useState([]);
  const [showPassword, setShowPassword] = React.useState(false);
  const [confirmPassword, setConfirmPassword] = React.useState(false);
  const [ParentLocationData, setParentLocationData] = useState<any[]>([]);
  const [officeLocationData, setOfficeLocationData] = useState<any[]>([]);
  const [designation, setDesignation] = useState<any[]>([]);
  const [IsCancel, setIsCancel] = useState<boolean>(false);

  const { user, userLoginResponse, currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);
  const [loader, setLoader] = useState<boolean>(false);
  const { dataTable } = useSelector((state: RootState) => state.departmentData);
  const { allUsers } = useSelector((state: RootState) => state.userData);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { locationTable, ParentLocationTable, islocationAdded, islocationDeleted, ToasterMsg, isLoading } = useSelector(
    (state: RootState) => state.locationMasterData
  );
  const { BranchByCompanyTable } = useSelector((state: RootState) => state.BranchMasterData);
  useEffect(() => {
    const country = locationTable.filter((item: any) => item.Location_TypeId === userConfig.Country && item.IsActive === "Active");
    setParentLocationData(country);
  }, [ParentLocationTable]);

  useEffect(() => {
    const filtered = lovTable.filter((item: any) => item.Name === LovConfig.Designation) || []
    setDesignation(filtered);
  }, [lovTable]);

  const getLocations = (name: string) => {
    const matchingLocations = locationTable.filter((item: any) => item.Location_Name === name && item.IsActive === "Active");
    if (matchingLocations.length > 0) {
      const stateLocations = locationTable.filter((item: any) => item.Location_TypeId === userConfig.State && item.IsActive === "Active");
      setOfficeLocationData(stateLocations);
    }
  };

  let formData: userFormData = {
    FirstName:
      view || edit
        ? userdata && userdata.FirstName
          ? userdata.FirstName
          : ""
        : isDefault && defaultdata
          ? defaultdata.FirstName
          : defaultdata
            ? defaultdata.FirstName
            : "",
    LastName:
      view || edit
        ? userdata && userdata.LastName
          ? userdata.LastName
          : ""
        : isDefault && defaultdata
          ? defaultdata.LastName
          : defaultdata
            ? defaultdata.LastName
            : "",
    // LastName: (view || edit) ? (userdata && userdata.LastName ? userdata.LastName : "") : "",
    EmailId:
      view || edit
        ? userdata && userdata.EmailId
          ? userdata.EmailId
          : ""
        : isDefault && defaultdata
          ? defaultdata.EmailId
          : defaultdata
            ? defaultdata.EmailId
            : "",
    Mobile:
      view || edit
        ? userdata && userdata.Mobile
          ? userdata.Mobile
          : ""
        : isDefault && defaultdata
          ? defaultdata.Mobile
          : defaultdata
            ? defaultdata.Mobile
            : "",
    Country:
      view || edit
        ? userdata && userdata.Country
          ? userdata.Country
          : ""
        : isDefault && defaultdata
          ? defaultdata.Country
          : defaultdata
            ? defaultdata.Country
            : "",
    DepartmentId:
      view || edit
        ? userdata && userdata.DepartmentId
          ? userdata.DepartmentId
          : ""
        : isDefault && defaultdata
          ? defaultdata.DepartmentId
          : defaultdata
            ? defaultdata.DepartmentId
            : "",
    ManagerId:
      view || edit
        ? userdata && userdata.ManagerId
          ? userdata.ManagerId
          : ""
        : isDefault && defaultdata
          ? defaultdata.ManagerId
          : defaultdata
            ? defaultdata.ManagerId
            : "",
    Escalation:
      view || edit
        ? userdata && userdata.Escalation
          ? userdata.Escalation
          : ""
        : isDefault && defaultdata
          ? defaultdata.Escalation
          : defaultdata
            ? defaultdata.Escalation
            : "",
    BranchId:
      view || edit
        ? userdata && userdata.BranchId
          ? userdata.BranchId
          : ""
        : isDefault && defaultdata
          ? defaultdata.BranchId
          : defaultdata
            ? defaultdata.BranchId
            : "",
    OfficeLocation: null,
    OfficeAddress:
      view || edit
        ? userdata && userdata.OfficeAddress
          ? userdata.OfficeAddress
          : ""
        : isDefault && defaultdata
          ? defaultdata.OfficeAddress
          : defaultdata
            ? defaultdata.OfficeAddress
            : "",
    IsActive:
      view || edit
        ? userdata && userdata.IsActive
          ? userdata && userdata.IsActive === "Active"
            ? true
            : false
          : false
        : isDefault && defaultdata
          ? defaultdata && defaultdata.IsActive === 1
            ? true
            : true
          : true,
    // RoleId: view || edit ? (userdata && userdata.RoleId ? userdata.RoleId : null) : null,
    RoleId:
      view || edit
        ? userdata && userdata.RoleId
          ? userdata.RoleId
          : ""
        : isDefault && defaultdata
          ? defaultdata.RoleId
          : defaultdata
            ? defaultdata.RoleId
            : "",
    DesignationId:
      view || edit
        ? userdata && userdata.DesignationId
          ? userdata.DesignationId
          : ""
        : isDefault && defaultdata
          ? defaultdata.DesignationId
          : defaultdata
            ? defaultdata.DesignationId
            : "",
  };
  const {
    handleSubmit: handleUserForm,
    reset,
    setValue,
    formState: { errors: errorsloginform, isDirty },
    control,
    watch,
    getValues,
  } = useForm<userFormData>({
    defaultValues: formData,
    mode: "onChange",
  });

  const isActiveDisabled = watch("IsActive");
  const emailId = watch("EmailId");

  useEffect(() => {
    setisEditRoleData(null);
    setIsEdit(false);
    let selRole = watch("RoleId");

    if (selRole && allRoles.roles) {
      let selRoleMenu = allRoles.roles.find((item: any) => item.RoleId === selRole);
      let allMenu: IRole = JSON.parse(JSON.stringify(selRoleMenu))
      if (selRoleMenu) {
        // let GetMenu: IMenu[] = JSON.parse(JSON.stringify(selRoleMenu.Menus));
        // console.log("buvi ~ useEffect ~ GetMenu:", GetMenu)
        const r = allMenu?.Menus.map(i => {
          i.disabled = true;
          if (i.SubMenus) {
            i.SubMenus = i.SubMenus.map((sm) => {
              sm.disabled = true;
              if (sm.SubMenus) {
                sm.SubMenus = sm.SubMenus.map((s_sm) => {
                  s_sm.disabled = true;
                  return s_sm
                })
              }
              return sm
            })
          }
          return i
        })
        allMenu.Menus = [...r, ...existingMenu]
        setIsEdit(true);
        setisEditRoleData(allMenu);
      }
    }
  }, [watch("RoleId"), existingMenu]);
  const countryValue = getValues("Country");
  useEffect(() => {
    if (countryValue) {
      getLocations(countryValue);
    }
  }, [countryValue]);

  const handleSubmit = (data: any) => {
    dispatch(updateLoader(true));
    let formData: any = {};
    setLoader(true);

    formData.IsActive = data.IsActive ? 1 : 0;
    formData.CreatedBy = user?.UserName;
    formData.CreatedUserId = userLoginResponse?.UserId;
    formData.createUser = user?.UserRole;
    formData.DepartmentId = data.DepartmentId;
    formData.ManagerId = data.ManagerId;
    formData.RoleId = data.RoleId;
    formData.MenuId = currentPagePermissionData?.MenuId;
    formData.Updated_On = new Date();
    formData.OfficeAddress = data.OfficeAddress;
    formData.OfficeLocation = "";
    formData.BranchId = data.BranchId;
    formData.Mobile = `${data.Mobile}`;
    formData.LastName = data.LastName;
    formData.FirstName = data.FirstName;
    formData.EmailId = data.EmailId;
    formData.Country = data.Country;
    formData.DesignationId = data.DesignationId;
    formData.Form_Id = FormIdConfig?.userMasterFormId;
    // lovTable["Purpose"]?.data.find((item: any) => item.LovId == formData?.Document_Purpose)?.Options || '',
    // json date is used to Pending approval purpose
    let JsonDataForDisplay: any = {
      "First Name": formData.FirstName,
      "Last Name": formData.LastName,
      "Email Id": formData.EmailId,
      "Mobile": formData.Mobile,
      "Reporting To": allUsers.find((item: any) => item.UserId == formData?.ManagerId)?.EmailId || '',
      "Country": formData.Country,
      "Office Address": formData.OfficeAddress,
      "Department":dataTable.find((item:any)=>item.DepartmentId == formData?.DepartmentId).DepartmentName,
      "Role": roleMaster && roleMaster.length > 0 ? roleMaster.find((item: any) => item.RoleId == formData?.RoleId)?.RoleName || '' : '',
      "Status": data.IsActive ? "Active" : "InActive",
      "DesignationId": designation.find((item: any) => item.LovId == formData?.DesignationId)?.Options || '',
      "BranchId": BranchByCompanyTable && BranchByCompanyTable.length > 0 ? BranchByCompanyTable.find((item: any) => item.BranchId == formData?.BranchId)?.BranchName || '' : '',
      "Modified By": user?.UserName,
      "userMenus": finalMenuData
    }

    if (isDefault) {
      if (defaultdata && defaultId) {
            // update default user values call api
        let request = {
          DefaultId: defaultId,
          ModuleName: currentPagePermissionData?.MenuName,
          ScreenName: currentPagePermissionData?.MenuName,
          Activity: ToasterActivityConfig?.default,
          DefaultData: JSON.stringify(formData),
          UpdatedBy: user?.UserName,
        };
        dispatch(updateDefaultValue(request));
      } else {
          // create default user values call api
        let request = {
          ModuleName: currentPagePermissionData?.MenuName,
          ScreenName: currentPagePermissionData?.MenuName,
          Activity: ToasterActivityConfig?.default,
          DefaultData: JSON.stringify(formData),
          CreatedBy: user?.UserName,
        };
        dispatch(addDefaultValue(request));
      }
    } else {
      if (edit) {
        // edit user call api
        formData.UserId = userdata?.UserId;
        formData.ModuleName = currentPagePermissionData?.MenuName;
        formData.Activity = ToasterActivityConfig?.Edit;
        formData.UpdatedUserId = userLoginResponse?.UserId;
        formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);
        formData.usersMenus = addedMenu.map((item) => {
          const wanted = (({ MenuId, IsAdd, IsEdit, IsDelete, IsView, IsExport, IsImport, IsApproval }) => ({ MenuId, IsAdd, IsEdit, IsDelete, IsView, IsExport, IsImport, IsApproval }))(item)
          return { ...wanted, UserId: userdata?.UserId, CreatedBy: userLoginResponse?.UserId, UpdatedBy: userLoginResponse?.UserId }
        })
        dispatch(addUser(formData));
      } else {
         // create user call api
        formData.ModuleName = currentPagePermissionData?.MenuName;
        formData.Activity = ToasterActivityConfig?.create;
        formData.UserId = 0;
        formData.CreatedUserId = userLoginResponse?.UserId;
        formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);
        formData.usersMenus = addedMenu.map((item) => {
          const wanted = (({ MenuId, IsAdd, IsEdit, IsDelete, IsView, IsExport, IsImport, IsApproval }) => ({ MenuId, IsAdd, IsEdit, IsDelete, IsView, IsExport, IsImport, IsApproval }))(item)
          return { ...wanted, UserId: 0, CreatedBy: userLoginResponse?.UserId, UpdatedBy: userLoginResponse?.UserId }
        })
        dispatch(addUser(formData));
      }
    }
  };

  useEffect(() => {
    if (isUserAdded || isUserUpdated) {
      dispatch(
        getUsersList({
          FormID: 2,
        })
      );
      reset(formData);
      dispatch(updateLoader(false));
      if (closePopUp) {
        closePopUp();
        setLoader(false);
      }
    }

    if (isDefaultValuedAddedd || isDefaultValuedUpdated) {
      if (closePopUp) {
        closePopUp();
        setLoader(false);
        dispatch(updateLoader(false));

      }
    }
  }, [isUserAdded, isUserUpdated, isDefaultValuedAddedd, isDefaultValuedUpdated]);

  // const updateMenu = async()=>{
  //   const response = await userListService.getUsersList({FormID: 2});
  //   const { Users } = response.data;
  //   const createdUser = Users.filter((i)=>i.EmailId=== emailId)?.[0]
  //   let userMenuData : any = {}
  //   userMenuData.ModuleName = "User Management";
  //   userMenuData.Activity = edit ? "Edit" : "Create" ;
  //   userMenuData.CreatedBy = userLoginResponse?.UserId;
  //   userMenuData.usersMenus = addedMenu.map((item)=>{
  //     const wanted = (({ MenuId, IsAdd,IsEdit,IsDelete,IsView,IsExport,IsImport,IsApproval }) => ({ MenuId, IsAdd,IsEdit,IsDelete,IsView,IsExport,IsImport,IsApproval }))(item)
  //     return {...wanted,UserId:createdUser?.UserId,CreatedBy:userLoginResponse?.UserId,UpdatedBy:userLoginResponse?.UserId}
  //   })
  //   dispatch(saveUserMenu(userMenuData))
  // }
  // useEffect(() => {
  //   if (addUserInitiated) {
  //     updateMenu()
  //   }
  // }, [addUserInitiated]);

  const fetchMenuByUser = async () => {
    let res = await loginService.getMenuByUserId({ UserId: userdata && userdata.UserId })
    console.log("buvi ~ fetchMenuByUser ~ res:", res)
    setExistingMenu(res?.data)
  }
  useEffect(() => {
    if (view || edit) {
      fetchMenuByUser()
    }
  }, [view, edit]);

  const handleCancelPopup = () => {

    if (isDirty) {
      setIsCancel(true);
    } else {
      closePopUp && closePopUp();
      reset(formData);
    }
  };

  return (
    <>
      <Grid container component="main" sx={{ height: "auto", width: "100%", overflow: "hidden", position: "relative" }}>
        {/* {loader ? <ProgressBar showProgress={loader} /> : null} */}
        {/* <Grid 
        // item
        // item xs={12} sm={12} md={6} lg={6} xl={6}
        // sx={{
        //   backgroundImage: `url()`,
        //   backgroundRepeat: "no-repeat",
        //   // backgroundColor: (t) =>
        //   //   t.palette.mode === "light" ? "#F5F5F5" : "#F5F5F5",
        //   // backgroundSize: "cover",
        //   backgroundPosition: "center",
        //   // height: "100%",
        //   width: "100%",
        //   display: "flex",
        //   flexDirection: "column",
        //   alignItems: "flex-start",
        //   justifyContent: "center",
        //   margin: "0",
        // }}
        
>*/}

        <Grid
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              px: {
                xs: 2,
                sm: 4,
                md: 2,
                lg: 2,
                xl: 2,
              },

              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="FirstName"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: isDefault ? false : "First Name is Required",
                      validate: (data) => {
                        return isDefault || data.length >= 4 || "Minimal 4 characters requires";
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"firstName"}
                        required={isDefault ? false : true}
                        readOnly={view || !isActiveDisabled}
                        label={"First Name"}
                        value={field.value}
                        onChange={(value) => {
                          if (/^[a-zA-Z\s]*$/.test(value)) {
                            field.onChange(value);
                          }
                        }}
                        error={Boolean(errorsloginform.FirstName)}
                        helperText={errorsloginform.FirstName && errorsloginform.FirstName.message?.toString()}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="LastName"
                    control={control}
                    defaultValue={""}
                    // rules={{
                    //   required: isDefault? false:"Last Name is Required",
                    //   validate: (data) => {
                    //     return  isDefault || data.length >= 4 || "Minimal 4 characters requires";
                    //   },
                    // }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"lastName"}
                        // required={isDefault? false:true}
                        label={"Last Name"}
                        value={field.value}
                        readOnly={view || !isActiveDisabled}
                        onChange={(value) => {
                          if (/^[a-zA-Z\s]*$/.test(value)) {
                            field.onChange(value);
                          }
                        }}
                        error={Boolean(errorsloginform.LastName)}
                        helperText={errorsloginform.LastName && errorsloginform.LastName.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="EmailId"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: isDefault ? false : "Email is Required",
                      pattern: !isDefault
                        ? {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: "Invalid email address",
                        }
                        : undefined,
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"emailId"}
                        required={isDefault ? false : true}
                        label={"Email"}
                        value={field.value}
                        readOnly={view || edit || !isActiveDisabled}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.EmailId)}
                        helperText={errorsloginform.EmailId && errorsloginform.EmailId.message?.toString()}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="Mobile"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: isDefault ? false : "Phone Number is Required",
                      validate: (data) => {
                        return isDefault || /^\d{10}$/.test(data) || "Phone Number is not valid";
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"mobile"}
                        required={isDefault ? false : true}
                        type={"number"}
                        label={"Phone Number"}
                        value={field.value}
                        readOnly={view || !isActiveDisabled}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.Mobile)}
                        helperText={errorsloginform.Mobile && errorsloginform.Mobile.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="DepartmentId"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: isDefault ? false : "Department is Required",
                    }}
                    render={({ field }) => (
                      <CustomSelect
                        name="Department"
                        valueKey={"DepartmentId"}
                        optionLabelKey="DepartmentName"
                        options={dataTable.filter((item: any) => item.IsActive !== "Inactive")}
                        required={isDefault ? false : true}
                        label={"Department"}
                        value={field.value}
                        onChange={field.onChange}
                        readOnly={view || !isActiveDisabled}
                        error={errorsloginform.DepartmentId && errorsloginform.DepartmentId.message?.toString()}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="ManagerId"
                    control={control}
                    defaultValue={""}
                    // rules={{
                    //   required: isDefault ? false : "Reporting To is Required",
                    // }}
                    render={({ field }) => (
                      <CustomSelect
                        name="Reporting To"
                        valueKey={"UserId"}
                        optionLabelKey="EmailId"
                        options={allUsers.filter((item: any) => item.IsActive !== "Inactive")}
                        required={isDefault ? false : true}
                        label={"Reporting To"}
                        value={field.value}
                        onChange={field.onChange}
                        readOnly={view || !isActiveDisabled}
                        error={errorsloginform.ManagerId && errorsloginform.ManagerId.message?.toString()}
                        helperText={errorsloginform.ManagerId && errorsloginform.ManagerId.message?.toString()}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="Country"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: isDefault ? false : "Country is Required",
                    }}
                    render={({ field }) => (
                      <CustomSelect
                        name="country"
                        valueKey={ParentLocationData && ParentLocationData.length > 0 ? "Location_Name" : "value"}
                        optionLabelKey={ParentLocationData && ParentLocationData.length > 0 ? "Location_Name" : "value"}
                        options={
                          ParentLocationData && ParentLocationData.length > 0 ? ParentLocationData : countryDropDown
                        }
                        required={isDefault ? false : true}
                        label={"Country"}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e);
                          getLocations(e);
                        }}
                        readOnly={view || !isActiveDisabled}
                        error={errorsloginform.Country && errorsloginform.Country.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="BranchId"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: isDefault ? false : "branch is Required",
                    }}
                    render={({ field }) => (
                      <CustomSelect
                        // cascadingKey={"country"}
                        // cascadingvalue={watch("Country")}
                        name="BranchId"
                        // valueKey={"value"}
                        // optionLabelKey="value"
                        // options={stateDropDown}
                        valueKey={"BranchId"}
                        optionLabelKey={"BranchName"}
                        options={BranchByCompanyTable && BranchByCompanyTable.length > 0 ? BranchByCompanyTable : []}
                        required={isDefault ? false : true}
                        label={"Branch"}
                        value={field.value}
                        onChange={field.onChange}
                        readOnly={view || !isActiveDisabled}
                        error={errorsloginform.BranchId && errorsloginform.BranchId.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="OfficeAddress"
                    control={control}
                    defaultValue={""}
                    // rules={{
                    //   required: isDefault? false:"officeAddress is Required",
                    // }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"officeAddress"}
                        // required={isDefault ? false : true}
                        label={"Office Address"}
                        value={field.value}
                        readOnly={view || !isActiveDisabled}
                        onChange={field.onChange}
                      // error={Boolean(errorsloginform.OfficeAddress)}
                      // helperText={errorsloginform.OfficeAddress && errorsloginform.OfficeAddress.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="RoleId"
                    control={control}
                    rules={{
                      required: isDefault ? false : "Role Type is Required",
                    }}
                    render={({ field }) => (
                      <CustomSelect
                        name="select"
                        valueKey={"RoleId"}
                        optionLabelKey="RoleName"
                        options={roleMaster && roleMaster.length > 0 ? roleMaster : []}
                        required={isDefault ? false : true}
                        // label={"Role Type"}
                        label={"Role"}
                        readOnly={view || !isActiveDisabled}
                        value={field.value}
                        onChange={field.onChange}
                        error={errorsloginform.RoleId && errorsloginform.RoleId.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="DesignationId"
                    control={control}
                    rules={{
                      required: isDefault ? false : "Designation is Required",
                    }}
                    render={({ field }) => (
                      <CustomSelect
                        name="select"
                        valueKey="LovId"
                        optionLabelKey="Options"
                        options={designation}
                        required={isDefault ? false : true}
                        label={"Designation"}
                        readOnly={view || !isActiveDisabled}
                        value={field.value}
                        onChange={field.onChange}
                        error={errorsloginform.RoleId && errorsloginform.RoleId.message?.toString()}
                      />
                    )}
                  />
                </Grid>
                {/* <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
                sx={{
                  display: "flex",
                  alignItems: "center"
                }}    
              >
                <Controller
                  name="IsActive"
                  control={control}
                  render={({ field }) => (
                    <CustomToogleSwitch
                      label={"User Status"}
                      dynamicLabel={field.value ? ACTIVE : INACTIVE}
                      value={field.value}
                      disabled={view}
                      onChange={(data) => {
                        setValue("IsActive", data);
                      }}
                    />
                  )}
                />
              </Grid> */}
              </Grid>

              {(isEditRoleData && isEdit) && (
                <Box sx={{ padding: "0px",}}>

                  <AddRole
                    userBased={true}
                    edit={isEdit}
                    view={view || !isActiveDisabled}
                    roleData={
                      isEditRoleData && isEditRoleData !== undefined && isEditRoleData !== null ? isEditRoleData : null
                    }
                    updateRoleData={setAddedMenu}
                    setFinalMenuData={setFinalMenuData}
                  />
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "right",
                  mt: 2,
                }}
              >
                {view ? (
                  <CustomButton
                    type="reset"
                    variant="contained"
                    //color="inherit"
                    name={"Cancel"}
                    onClick={() => {
                      reset(formData);
                      closePopUp && closePopUp();
                      setOfficeLocationData([]);
                      setParentLocationData([]);
                    }}
                  />
                ) : edit ? (
                  <React.Fragment>
                    <CustomButton
                      type="reset"
                      variant="outlined"
                      name={"Cancel"}
                      onClick={() => {
                        handleCancelPopup();
                        setOfficeLocationData([]);
                        setParentLocationData([]);
                      }}
                    />
                    <CustomButton
                      type="reset"
                      disabled={!isDirty && addedMenu.length === 0}
                      name={"Update"}
                      onClick={handleUserForm(handleSubmit)}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <CustomButton
                      type="reset"
                      variant="outlined"
                      name={"Cancel"}
                      onClick={() => {
                        // reset(formData);
                        handleCancelPopup();
                        setOfficeLocationData([]);
                        setParentLocationData([]);
                      }}
                    />
                    <CustomButton type="reset" name={"Submit"} onClick={handleUserForm(handleSubmit)} />
                  </React.Fragment>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <ModalPopUp
        onDeleteMethod={() =>{closePopUp && closePopUp(); reset(formData);}}
        isDelete={true}
        onHide={() => setIsCancel(false)}
        show={IsCancel}
        header={"Confirm"}
        text={`Are you sure to cancel`}
        ActionName={"Yes"}
      />
    </>
  );
};

export default AddUser;
