import React, { useEffect, useState } from "react";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import {
  ProcessDetailContext,
  RolesandResponsibility,
} from "../../vendorOnBoarding/rolesAndResponsibility/RolesAndResponsibility";
import {
  ACTIVE,
  addWorkingDays,
  EstimateSpend,
  getDateOrConvertSameTime,
  textFieldWidth,
} from "../../../utils/constants";
import { ProcessDetails } from "../../vendorOnBoarding/rolesAndResponsibility/ProcessDetails";
import { useSelector } from "react-redux";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import { getWorkList, setWorkFlowData } from "../../../store/slices/workFlowSlice";
import { getLovList } from "../../../store/slices/lovMasterData";
import { Box, Stack, Grid, Typography, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import {
  deleteRRSetup,
  getRolesAndResponsibilityList,
  updaterolesandresponsAddandEdit,
} from "../../../store/contractManagement/slices/contractRolesAndResponsiblity";

import { updateLoader, updateToaster } from "../../../store/slices/loader";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { ContractDetails } from "../PMCommonDetails/ContractDetails";
import CustomDatePicker from "../../../common/dynamicInputs/CustomDatePicker";
import CustomNumberField from "../../../common/dynamicInputs/CustomNumberField";
import CustomBreadcrumbs from "../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import { CreateRandRReview, PmRandRAddandSave } from "../../../store/performanceManagement/slices/pmRandRReview";
import { getNotifications } from "../../../store/slices/notification";
interface ValidationErrors {
  [key: string]: string;
}

interface FormData {
  EventId?: any;
  contract_Id?: any;
  Workflow_Start_Date?: any;
  Workflow_End_Date?: any;
  Workflow_Id?: any;
  Tat?: any;
  isActive: boolean;
}
export interface RolesandResponsibilityProps {
  view?: boolean;
  isViewOnly?: boolean;
  isEditData?: any;
  onClose?: () => void;
  prePageName?: any;
  ModuleId?: number | undefined;
}
const RAndRReview: React.FC<RolesandResponsibilityProps> = ({
  view,
  isEditData,
  onClose,
  prePageName,
  isViewOnly,
}) => {
  const [contractdetails, setcontractdetails] = useState<any>({});
  const performanceManagementReducer: any = useSelector((state: RootState) => state?.performanceManagementReducer);
  const { isRandRAdded, ToasterMsg } = useSelector((state: RootState) => performanceManagementReducer.PmRandRReviewDataSlice);
  const [selectedWorkflow, setSelectedWorkflow] = useState("");
  const { user, userLoginResponse, currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);
  const [workflowProcess, setWorkflowProcess] = useState<any[]>([]);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  const { allUsers } = useSelector((state: RootState) => state.userData);
  const { HolidayTable } = useSelector((state: RootState) => state.HolidayMasterData);
  const [ischangeForm, setischangeForm] = useState<boolean>(false);
  const dispatch = useAppDispatchThunk();
  const [selectedData, setSelectedData] = useState<any>(null);
  const { workFlowsData } = useSelector((state: RootState) => state.workFlow);
  const [projectNameWarning, setProjectNameWarning] = useState("");
  const [HolidayData, setHolidayData] = useState<any[]>([]);
  const [vendorConsentData, setVendorConsentData] = useState<any>();
  const formData = {
    EventId: isEditData ? isEditData.pR_EventLog_Id : 0,
    contract_Id: isEditData ? isEditData.contract_Id : 0,
    Workflow_Start_Date: isEditData ? isEditData.Workflow_Start_Date : null,
    Workflow_End_Date: isEditData ? isEditData.Workflow_End_Date : null,
    Workflow_Id: isEditData ? isEditData.Workflow_Id : "",
    Tat: isEditData ? isEditData.Tat : "",
  }
  const {
    contractRolesAndResponsibilityData,
    isLoading,
    RolesAndResonsibilitytoasterMsg,
    isRolesAndResponsibilityAdded,
  } = useSelector((state: any) => state.contractManagementReducer?.contractRolesAndResponsibility);
  const [activeWorkFlow, setActiveWorkFlow] = useState<any[]>([]);
  const {
    handleSubmit: handleRandRForm,
    reset,
    setValue,
    formState: { errors: errorrRolesandResponsibility, isDirty },
    control,
    watch,
    getValues,
    trigger,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });


  useEffect(() => {
    // Set the contract details from isEditData when component mounts or data changes
    setcontractdetails({
      companyName: isEditData.company_Name,
      vendorName: isEditData.vendor_Name,
      contractRefNumber: isEditData.contract_Id,
      pM_Ref_Number: isEditData.pR_Ref_No,
    });
  }, [isEditData]);
  useEffect(() => {
    callPAgeLoadData();
  }, []);

  const callPAgeLoadData = () => {
  };

  useEffect(() => {
    if (workFlowsData) {
      const configuredWorkFlows = contractRolesAndResponsibilityData.map((item: any) => item.Workflow_Id);
      let ActiveWorkflowList = workFlowsData.filter(
        (item: any) => item?.IsActive === "Active" && (isEditData || !configuredWorkFlows.includes(item.Id))
      );
      setActiveWorkFlow(ActiveWorkflowList);
      setWorkFlowData(ActiveWorkflowList);
      if (isEditData) {
        setSelectedWorkflow(isEditData.Workflow_Id);
        let data = workFlowsData.find((item) => item.Id === isEditData.Workflow_Id);
        data = updateSelectedWorkflow(data, isEditData);
        setSelectedData(data);
      }
    }
  }, [workFlowsData]);
  const initializeData = () => {
    dispatch(getRolesAndResponsibilityList(""));
  };

  useEffect(() => {
    initializeData();
  }, []);
  useEffect(() => {
    if (workFlowsData) {
      let ActiveWorkflowList = workFlowsData.filter(
        (item: any) => item?.IsActive === "Active");
      setActiveWorkFlow(ActiveWorkflowList);
      setWorkFlowData(ActiveWorkflowList);

    }
  }, [workFlowsData]);
  useEffect(() => {
    if (HolidayTable && HolidayTable.length > 0) {
      const ActiveHolidayList = HolidayTable.filter((item: any) => item.IsActive === "Active");
      setHolidayData(ActiveHolidayList);
    }
  }, [HolidayTable]);
  useEffect(() => {
    if (selectedData) {
      
      
      if (isViewOnly && isEditData.WorkflowProcessInstance) {
        let parsedWorkflowProcess = JSON.parse(isEditData.WorkflowProcessInstance);
        if (parsedWorkflowProcess) {
          const updatedDataArray = parsedWorkflowProcess?.map((item: any) => ({
            ProcessName: item?.Process_Name,
            ProcessType:item?.Process_Type,
            PrimaryUser: item.Primary_User ? item.Primary_User.split(",").map(Number) : [],
            SecondaryUser: item.Secondary_User ? item.Secondary_User.split(",").map(Number) : [],
            EscalateTo: item.EscalateTo ? item.EscalateTo.split(",").map(Number) : [],
            DepartmentName: item.Department ? item.Department.split(",").map(Number) : [],
            RoleId: item.Role ? item.Role : "",
          }));
          setWorkflowProcess(updatedDataArray);
        }
      } else if (selectedData.WorkflowProcessAsJson) {
        try {
          let parsedWorkflowProcess = JSON.parse(selectedData.WorkflowProcessAsJson);
          setWorkflowSteps(parsedWorkflowProcess);
        } catch (error) {
          console.error("Error parsing WorkflowProcessAsJson:", error);
        }
      }

    }
  }, [selectedData]);



  const setWorkflowSteps = (Allsteps: any) => {
    try {
      let parsedWorkflowProcess = Allsteps;
      if (Array.isArray(parsedWorkflowProcess)) {
        parsedWorkflowProcess = parsedWorkflowProcess.reduce((acc: any, item: any) => {
          if (item.IsActive)
            acc.push({
              ...item,
              Process_Name: item?.ProcessName,
              DepartmentName: item?.DepartmentId
                ? item?.DepartmentId?.split(",").map((ditem: any) => parseInt(ditem, 10))
                : [],
              Role: item?.RoleId,
              PrimaryUser: [],
              SecondaryUser: [],
              EscalateTo: [],
            });
          return acc;
        }, []);
      }
      setWorkflowProcess(parsedWorkflowProcess);
    } catch (error) {
      console.error("Error parsing WorkflowProcessAsJson:", error);
    }
  };
  const WorkflowStartDate = watch("Workflow_Start_Date");
  const WorkflowEndDate = watch("Workflow_End_Date");
  const tat = watch("Tat");
  useEffect(() => {
    if (WorkflowStartDate && tat && !isNaN(tat) && !view) {
      const endDate = addWorkingDays(WorkflowStartDate, tat, HolidayData).toDate();
      setValue("Workflow_End_Date", dayjs(endDate));
    }
  }, [WorkflowStartDate, setValue, tat, view, HolidayData]);


  useEffect(() => {
    dispatch(getWorkList());
    dispatch(getLovList({ Name: "" }));
  }, []);

  const updateSelectedWorkflow = (data: any, editData: any) => {
    if (data) {
      if (data.WorkflowProcessAsJson) {
        let parsedEditData = JSON.parse(editData.WorkflowProcessDetail);
        let parsedWorkflowProcess = JSON.parse(data.WorkflowProcessAsJson);
        const updatedDataArray = parsedWorkflowProcess?.map((item: any) => {
          const selectedEditData = parsedEditData.find((editItem: any) => editItem.Process_Id === item.ProcessId);
          if (selectedEditData) {
            return {
              ...item,
              Id: selectedEditData.Id,
              PrimaryUser: selectedEditData.PrimaryUser
                ? selectedEditData.PrimaryUser.split(",").map(Number)
                : item.PrimaryUser ?? [],
              DepartmentName: selectedEditData.DepartmentName || "",
              SecondaryUser: selectedEditData.SecondaryUser
                ? selectedEditData.SecondaryUser.split(",").map(Number)
                : item.SecondaryUser ?? [],
              EscalateTo: selectedEditData.EscalateTo
                ? selectedEditData.EscalateTo.split(",").map(Number)
                : item.EscalateTo ?? [],
              DepartmentId: selectedEditData.DepartmentName || "",
              Role: selectedEditData.RoleId || "",
            };
          }
          return item;
        });
        return {
          ...data,
          WorkflowProcessAsJson: JSON.stringify(updatedDataArray),
        };
      }
    }
    return data;
  };

  const Validatedepartmentuser = (selDepartments: any, selPrimaryusers: any) => {
    if (!selDepartments || !selPrimaryusers) return false;

    try {
      return selDepartments.some((dep_item: any) => {
        return selPrimaryusers.every((user_item: any) => {
          const userData = allUsers.find((user: any) => user.UserId === user_item);
          return userData?.DepartmentId !== dep_item;
        });
      });
    } catch {
      return true;
    }
  };

  const validateField = (index: number, field: string, value: any) => {
    let errors: { [key: string]: string } = { ...validationErrors };

    switch (field) {
      case "RoleId":
        if (!value) {
          errors[`${index}-RoleId`] = "Role is required";
        } else {
          delete errors[`${index}-RoleId`];
        }
        break;

      case "TAT":
        if (!value || isNaN(value)) {
          errors[`${index}-TAT`] = "Estimated Time is required";
        } else {
          if (value && value <= 0) {
            errors[`${index}-TAT`] = "Please enter valid Estimated Time";
          } else if (value && value > 90) {
            errors[`${index}-TAT`] = "Please enter below 90 days";
          } else {
            delete errors[`${index}-TAT`];
          }
        }
        break;
      case "PrimaryUser":
        if (!value || value?.length === 0) {
          errors[`${index}-PrimaryUser`] = "At least one primary user is required";
        } else {
          const CheckWorkflowProcess = [...workflowProcess];
          const department = CheckWorkflowProcess[index]?.DepartmentName || [];
          if (value && Validatedepartmentuser(department, value)) {
            errors[`${index}-PrimaryUser`] = "At least one primary user in selected department";
          } else {
            delete errors[`${index}-PrimaryUser`];
          }
        }
        break;
      default:
        break;
    }

    setValidationErrors(errors);
    return errors;
  };

  const handleInputChange = (index: number, field: string, value: any) => {

    const newWorkflowProcess = [...workflowProcess];
    newWorkflowProcess[index][field] = value;
    if (field === "DepartmentName") {
      newWorkflowProcess[index].PrimaryUser = [];
      newWorkflowProcess[index].SecondaryUser = [];
      newWorkflowProcess[index].EscalateTo = [];
    }
    if (field === "RoleId") {
      newWorkflowProcess[index].PrimaryUser = [];
      newWorkflowProcess[index].SecondaryUser = [];
      newWorkflowProcess[index].EscalateTo = [];
    }
    if (field === "PrimaryUser") {
      let SecoundaryUserData = newWorkflowProcess[index].SecondaryUser;
      if (SecoundaryUserData && SecoundaryUserData?.length > 0) {
        const filteredSecondaryUsers = SecoundaryUserData.filter((user: any) => !value.includes(user));
        newWorkflowProcess[index].SecondaryUser = filteredSecondaryUsers;
      }
    }
    if (field === "PrimaryUser" || field === "SecondaryUser") {
      const escalateToSet = new Set();

      const usersList = [...(newWorkflowProcess[index].SecondaryUser || []), ...newWorkflowProcess[index].PrimaryUser];

      usersList.forEach((userId: any) => {
        const managerId = allUsers.find((user: any) => user.UserId === userId)?.ManagerId;
        if (managerId) {
          escalateToSet.add(managerId);
        }
      });


      newWorkflowProcess[index].EscalateTo = Array.from(escalateToSet);
    }

    setValue(
      "Tat",
      newWorkflowProcess.reduce((n, { TAT, IsActive }) => (IsActive ? n + Number(TAT) : n), 0)
    );
    setischangeForm(true);
    setWorkflowProcess(newWorkflowProcess);
    validateField(index, field, value);
  };
  useEffect(() => {
    const newWorkflowProcess = [...workflowProcess];
    setValue(
      "Tat",
      newWorkflowProcess.reduce((n, { TAT, IsActive }) => (IsActive ? n + Number(TAT) : n), 0)
    );
  }, [workflowProcess])
  const validateAllFields = () => {
    let isValid = true;
    let allErrors: { [key: string]: string } = {};

    workflowProcess.forEach((item, index) => {
      const roleErrors = validateField(index, "RoleId", item.RoleId);
      const departmentErrors = validateField(index, "DepartmentName", item.DepartmentName);
      const tatErrors = validateField(index, "TAT", item.TAT);
      const primaryUserErrors = validateField(index, "PrimaryUser", item.PrimaryUser);
      // const secondaryUserErrors = validateField(index, "SecondaryUser", item.SecondaryUser);
      // const escalateToErrors = validateField(index, "EscalateTo", item.EscalateTo);

      allErrors = {
        ...allErrors,
        ...roleErrors,
        ...departmentErrors,
        ...tatErrors,
        ...primaryUserErrors,
      };
    });

    setValidationErrors(allErrors);

    if (Object.keys(allErrors).length > 0 || workflowProcess?.length === 0) {
      isValid = false;
    }

    return isValid;
  };
  const Workflow_Id = watch('Workflow_Id')
  useEffect(() => {
    const Randrdata = contractRolesAndResponsibilityData
    const data = Randrdata.find((item: any) => item?.Workflow_Id == Workflow_Id);
    if (data) {
      //  const data1 = updateSelectedWorkflow(data, isEditData);
      let parsedWorkflowProcess = JSON.parse(data.WorkflowProcessDetail);      
      if (parsedWorkflowProcess) {
        const updatedDataArray = parsedWorkflowProcess?.map((item: any) => ({
          ...item,
          Workflow_Id:data.Workflow_Id,
          ProcessName: item?.Process_Name,
          Branching:item.Branching,

          ProcessId:item?.Process_Id,
          Process_NextStep:item?.Process_NextStep,
          ConditionTobeChecked:item?.Condition_Tobe_Checked,
          ConditionName:item?.Condition_Name,
          IfYes_Goto:item?.IfYes_Goto,
          IfNo_Goto:item?.IfNo_Goto,
          ProcessSeqno:item?.Process_SeqNo,
          PrimaryUser: item.PrimaryUser ? item.PrimaryUser.split(",").map(Number) : [],
          SecondaryUser: item.Secondary_User ? item.SecondaryUser.split(",").map(Number) : [],
          EscalateTo: item.Escalate_To ? item.EscalateTo.split(",").map(Number) : [],
          DepartmentName: item.Department ? item.DepartmentName.split(",").map(Number) : [],
          RoleId: item.Role ? item.Role : "",
        }));
        setWorkflowProcess(updatedDataArray);        
      }
    }
    //  updateSelectedWorkflow(data,workflowProcess)
  }, [Workflow_Id])

  const handleSave = (data: any) => {
    const currentDate = new Date();
    const payload = {
      tat: data.Tat,
      event_Id: data.EventId,
      contract_Id: data.contract_Id,
      workflow_Start_Date: data.Workflow_Start_Date,
      workflow_End_Date: data.Workflow_End_Date,
      workflowId: data.Workflow_Id,
      userId: userLoginResponse?.UserId,
      moduleName: "Roles and Responsibility",
      activity: "Create",
      isActive:true,
      updated_On: currentDate.toISOString(),
      updated_By: user?.UserName,
      workflowProcessInstance: workflowProcess.map((item) => ({
        workflow_InstanceId: 0,
        Workflow_Id: item.WorkflowId ??  data.Workflow_Id,
        process_SeqNo: item.ProcessSeqno,
        process_Name: item.ProcessName,
        process_Type: item.ProcessType ? item.ProcessType: item.Process_Type ,
        process_Id: item.ProcessId ?? 0,
        process_NextStep: item.Process_NextStep,
        condition_ToBe_Checked: item.ConditionTobeChecked ?? "",
        ifYes_Goto: item.IfYes_GoTo ?? 0,
        ifNo_Goto: item.IfNo_GoTo ?? 0,
        branching: item.Branching ?? 0,
        tat: item.TAT,
        isActive: item.IsActive,
        updatedBy: item.Updated_By,
        updatedOn: currentDate.toISOString(),
        status: 0,
        exitStatus: item.Exit_Status ?? 0,
        role: item.RoleId,
        department: item.DepartmentName.join(","),
        primary_User: item.PrimaryUser.join(","),
        secondary_User: item.SecondaryUser.join(","),
        escalate_To: item.EscalateTo.join(","),
      })),


    };
    dispatch(CreateRandRReview(payload));
    closeForm(0)
  };

  const handleSubmit = () => {
    const validFields = validateAllFields();
    if (validFields) {
      handleRandRForm(handleSave)()
    }
  };
  const closeForm = (value: any) => {
    if (onClose && value === 0) {
      // if (isDirty) {
      //   // setchangeMaking(true);
      // } else {
      onClose();
      // }
    }
  };
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Stack spacing={2}>

          <Box sx={{ display: "flex", justifyContent: "left", mb: 2 }}>
            <CustomBreadcrumbs
              list={[
                { value: 0, name: prePageName ? prePageName : "Performance Preview Event Log" },
                { value: 1, name: `Roles and Responsiblity` },
              ]}
              onchange={closeForm}
            />
          </Box>


          <ContractDetails data={contractdetails} />

          <Box style={{ width: "100%" }}>
            <Box
              sx={{
                height: "100%",
                width: "100%",
                border: "1px solid #F7F7F7",
                padding: 2,
              }}
            >
              <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="Workflow_Start_Date"
                    control={control}
                    defaultValue={null}
                    rules={{ required: "Workflow Start Date is Required" }}
                    render={({ field }) => (
                      <CustomDatePicker
                        minDate={isEditData ? dayjs(isEditData?.Workflow_Start_Date).add(1, "day") : dayjs()}
                        maxDate={dayjs().add(100, "year").toDate()}
                        label={"Start Date"}
                        value={field.value ? dayjs(field.value) : null}
                        required={true}
                        readOnly={view}
                        onChange={field.onChange}
                        error={Boolean(errorrRolesandResponsibility.Workflow_Start_Date)}
                        helperText={
                          errorrRolesandResponsibility.Workflow_Start_Date &&
                          errorrRolesandResponsibility.Workflow_Start_Date.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="Workflow_End_Date"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Workflow End Date is Required",
                      validate: {
                        startDateValidation: (value) => {
                          if (WorkflowEndDate)
                            return (
                              dayjs(value) >= dayjs(WorkflowEndDate) ||
                              "Workflow End Date cannot be lesser than Project Start Date ."
                            );
                        },
                      },
                    }}
                    render={({ field }) => (
                      <CustomDatePicker
                        maxDate={dayjs().add(100, "year").toDate()}
                        label={"End Date"}
                        value={field.value ? dayjs(field.value) : null}
                        required={true}
                        readOnly={true}
                        onChange={field.onChange}
                        error={Boolean(errorrRolesandResponsibility.Workflow_End_Date)}
                        helperText={
                          errorrRolesandResponsibility.Workflow_End_Date &&
                          errorrRolesandResponsibility.Workflow_End_Date.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name={`Tat`}
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Tat is Required",
                      validate: (data: number | undefined) => {
                        if (data === undefined || isNaN(data)) {
                          return "Tat is Required";
                        }
                        const number = parseInt(data.toString(), 10);
                        let valuesFrom = 1;
                        let valuesTo = 60;
                        const numberFrom = parseInt(valuesFrom?.toString() ?? "", 10);
                        const numberTo = parseInt(valuesTo?.toString() ?? "", 10);
                        return true;
                      },
                    }}
                    render={({ field }) => (
                      <CustomNumberField
                        name={"Tat"}
                        required={true}
                        readOnly={true}
                        label={"TAT (In Days)"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorrRolesandResponsibility.Tat)}
                        helperText={
                          errorrRolesandResponsibility.Tat && errorrRolesandResponsibility.Tat.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name={`Workflow_Id`}
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Workflow  is Required",
                    }}
                    render={({ field }) => (
                      <CustomSelect
                        name={"WorkflowName"}
                        valueKey={"Id"}
                        optionLabelKey="WorkflowName"
                        options={activeWorkFlow}
                        value={field.value}
                        onChange={(val) => {
                          field.onChange(val);
                          setSelectedData(workFlowsData.find((item) => item.Id === val));
                        }}
                        required={true}
                        label={"WorkFlow"}
                        disabled={view}
                        error={Boolean(errorrRolesandResponsibility.Workflow_Id)}
                        helperText={
                          errorrRolesandResponsibility.Workflow_Id &&
                          errorrRolesandResponsibility.Workflow_Id.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>

              </Grid>
            </Box>
          </Box>
          <ProcessDetailContext.Provider value={{ workflowProcess, validationErrors, handleInputChange }}>
            <ProcessDetails view={view} isdisablecurrent={false} />
          </ProcessDetailContext.Provider>
          <Stack direction="row" spacing={2} justifyContent="flex-end" mt={1}>
            <CustomButton
              variant="outlined"
              name={"Cancel"}
              // disabled={props.isLoading}
              onClick={(e: any) => {
                // props.onHide();
              }}
            />
            <CustomButton
              variant="contained"
              disabled={view}
              name={"Save"}
              onClick={() => {
                handleSubmit();
              }}
            />
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default RAndRReview;
