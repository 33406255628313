import { Box, Divider, Stack, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import React, { useEffect, useRef, useState } from "react";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import SigningAuthority from "./SigningAuthority";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import {
  getGetVCWorkflowProcessesData,
  updateCmWorkFlowInstance,
  saveSigningAuthority,
  getSigningStatus,
  initiateEmbedSign,
  downloadWorkflowDocument,
  sendReminder,
  getSigningAuthorities,
  saveTempSigningAuthorities,
  convertToPDF,
} from "../../../store/contractManagement/slices/contractRolesAndResponsiblity";
import { useSelector } from "react-redux";
import CustomStepper from "../../../common/dynamicSteper/CustomStepper";
import StampingDetails from "./StampingDetailForm";
import DynamicBasicTable from "../../../common/dynamicbasicTable/DynamicBasicTable";
import { useForm } from "react-hook-form";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import { updateHeaderMenu, updateMenu } from "../../../store/slices/menu";
import OnlyOfficeTextEditor from "../../../common/textEditor/OnlyOfficeTextEditor";

interface INotifySigningAuthority {
  isEditData?: any;
  onClose?: any;
}

const NotifySigningAuthority: React.FC<INotifySigningAuthority> = ({ isEditData, onClose }) => {
  const [contractData, setContractData] = useState<any>("");
  const [contractBasicData, setContractBasicData] = useState<any>({
    "Company Name": "",
    "Vendor Name": "",
    "Contract Number": "",
  });

  const {
    watch,
    control,
    handleSubmit,
    setValue,
    formState: { errors: errorDetailsForm },
  } = useForm();

  const contractManagementReducer: any = useSelector((state: RootState) => state?.contractManagementReducer);
  const { workFLowInstanceData }: any = contractManagementReducer.contractRolesAndResponsibility;

  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );

  const [isSystemVaraible, setIsSystemVaraible] = useState(false);
  const [viewContract, setViewContract] = useState(false);

  const main_steps = ["Initiate E-Sign", "E-Stamping"];

  const contractActionColumn = [
    // { Field: "authority", DisplayName: "Authority" },
    { Field: "name", DisplayName: "Name" },
    { Field: "emailID", DisplayName: "Email ID" },
    // { Field: "designation", DisplayName: "Designation" },
    { Field: "status", DisplayName: "Sign Status" },
    { Field: "lastReminder", DisplayName: "Last Reminder Sent" },
  ];

  const dispatch = useAppDispatchThunk();
  const [signingAuthority, setSigningAuthority] = useState<any>({
    Company: [],
    Vendor: [],
  });

  const {
    isRolesAndResponsibilityAdded,
    isRolesAndResponsibilityUpdated,
    isLoading,
    signingStatus,
    signingAuthorities,
  }: any = contractManagementReducer.contractRolesAndResponsibility;

  const [stampingDetails, setStampingDetails] = useState<any>({});

  const [signStatus, setSigStatus] = useState<any>(null);
  const [activestepCount, setActivestepCount] = useState(0);
  const [isTemplateUpdated, setIsTemplateUpdated] = useState(false);
  const [signCompleted, setSignCompleted] = useState(false);

  useEffect(() => {
    if (signingStatus) {
      setSigStatus(signingStatus);
      if (signingStatus && signingStatus.eSignWorkFlowResponse && signingStatus.eSignWorkFlowResponse.length) {
        const signatories = signingStatus?.eSignWorkFlowResponse[0]?.signatories || [];
        const isSignCompleted = signatories.length ? signatories.every((data: any) => data.status === "Signed") : false;
        setActivestepCount(2);
        setSignCompleted(isSignCompleted);
      }
    }
  }, [signingStatus]);

  useEffect(() => {
    if (signingAuthorities) {
      const contractDetailsList = signingAuthorities?.contractDetailsList;
      if (contractDetailsList && contractDetailsList.length) {
        const tempAuthorities = {
          Company: contractDetailsList
            .filter((list: any) => list.type === "company")
            .map((data: any) => ({
              name: data.name,
              designation: data.designation,
              email: data.emailId,
              mobileNo: parseInt(data.mobile),
            })),
          Vendor: contractDetailsList
            .filter((list: any) => list.type === "vendor")
            .map((data: any) => ({
              name: data.name,
              designation: data.designation,
              email: data.emailId,
              mobileNo: parseInt(data.mobile),
            })),
        };
        setSigningAuthority(tempAuthorities);
        setActivestepCount(1);
      }
    }
  }, [signingAuthorities]);

  useEffect(() => {
    dispatch(updateLoader(isLoading));
  }, [isLoading]);

  const placeHolder = () => {};

  useEffect(() => {
    if (isEditData && isEditData !== null) {
      let request: any = null;
      if (isEditData?.ProjectId) {
        request = {
          projectId: isEditData?.ProjectId,
          workflowInstanceId: isEditData?.Workflow_InstanceId,
        };
      } else {
        request = {
          projectId: isEditData?.project_Id,
          workflowInstanceId: isEditData?.workflowInstance?.id,
        };
      }
      dispatch(getGetVCWorkflowProcessesData(request));
      dispatch(getSigningStatus({ contractNo: isEditData?.Assessment_Number }));
      dispatch(getSigningAuthorities({ contractNo: isEditData?.Assessment_Number }));
      // dispatch(getSigningAuthorities({ contractNo: "CT-8072" }));
      // dispatch(getSigningStatus({ contractNo: "CT-8072" }));
    }
    // let req: any = { projectId: "723", workflowInstanceId: 688 };
    // dispatch(getGetVCWorkflowProcessesData(req));
  }, []);

  useEffect(() => {
    if (workFLowInstanceData) {
      setContractData(workFLowInstanceData);
    }
  }, [workFLowInstanceData]);

  useEffect(() => {
    if (workFLowInstanceData) {
      let obj = {
        "Company Name": workFLowInstanceData?.company_Name,
        "Vendor Name": workFLowInstanceData?.vendor_Name,
        "Contract Number": workFLowInstanceData?.contract_Ref_Number,
      };
      setContractBasicData(obj);
    }
  }, [workFLowInstanceData]);

  const handleDataChange = (data: any, type: string) => {
    setSigningAuthority((prevData: any) => {
      return { ...prevData, [type]: data };
    });
  };

  const SigningAuthorityDetail = () => {
    const signatories = Array.isArray(signStatus?.eSignWorkFlowResponse)
      ? signStatus.eSignWorkFlowResponse[0]?.signatories || []
      : [];
    return (
      <Box>
        <Typography fontWeight="bold" textAlign="left" sx={{ marginTop: 4, marginBottom: 3, color: "#6a6a6a" }}>
          SIGNING AUTHORITY DETAILS
        </Typography>
        <Box>
          <DynamicBasicTable
            columns={contractActionColumn}
            rowData={signatories || []}
            style={{ border: "1px solid #f1f1f1" }}
            isEdit={false}
            //     isDelete={true}
            //   showDeleteButton={!view && !edit}
            disableAction={false}
            //     showDeleteButton={true}
            //    statusIdMapping={statusIdLabelMapping}
          />
        </Box>
      </Box>
    );
  };

  const replaceSystemVariable = async () => {
    let { REACT_APP_API_URL, REACT_APP_API_CM_URL } = process.env;
    const signingAuthorities = [...signingAuthority.Company, ...signingAuthority.Vendor];
    const signees = signingAuthorities.map((data) => data.name) || [];

    // const vendor_Contract_Id = "150";
    localStorage.setItem("processName", "System Variable");
    localStorage.setItem("APIURL", REACT_APP_API_URL ? REACT_APP_API_URL : "");
    localStorage.setItem("CMAPIURL", REACT_APP_API_CM_URL ? REACT_APP_API_CM_URL : "");
    localStorage.setItem("vendor_Contract_Id", contractData?.vendor_Contract_Id);
    localStorage.setItem("user_Id", userLoginResponse !== null ? String(userLoginResponse?.UserId) : "");
    localStorage.setItem("template_Name", contractData?.contract_Tempate_Name);
    localStorage.setItem("signees", JSON.stringify(signees));
    setIsSystemVaraible(true);
    dispatch(updateMenu(false));
    dispatch(updateHeaderMenu(false));
  };

  const handleNextStep = async (stampingData: any) => {
    setStampingDetails(stampingData);
    dispatch(updateLoader(true));
    const signingAuthorities = [...signingAuthority.Company, ...signingAuthority.Vendor];
    let payload: any = {};
    if (stampingData.stampingType === "online") {
      payload = {
        Name: "Clife",
        EmailId: "sukrutha.kini@clife.ai",
        referenceNo: workFLowInstanceData?.contract_Ref_Number,
        // referenceNo: "CT-8072",
        // SuppressEstampPage: true,
        eStampType: stampingData.stampingType,
        InitiateNESLFlow: true,
        // CollectPaymentfromParticipant: false,
        // EstampAmount: parseInt(stampingData.contractValue),
        SignatoryEmailIds: signingAuthorities.map((data) => data.email),
        // SignatureSettings: signingAuthorities.map((data) => ({
        //   Name: data.name,
        //   Gender: "",
        //   desingation: data.designation,
        //   mobileNo: data.mobileNo,
        // })),
        // DonotSendCompletionMailToParticipants: false,
        // DoNotNotifyCustomer: false,
        lstDocumentDetails: [
          {
            DocumentName: `${contractData.contract_Tempate_Name}.pdf`,
            // DocumentName: "Zlendo_Technologies_Pvt_Ltd_Thomas_Cook_India_Ltd_Web_Development_Services.pdf",
            FileData: "",
            ControlDetails: [
              {
                PageNo: 1,
                ControlID: 4,
                AssignedTo: 1,
                Left: 15,
                Top: 92,
                Height: 32,
                Width: 135,
              },
              {
                PageNo: 1,
                ControlID: 4,
                AssignedTo: 2,
                Left: 458,
                Top: 90,
                Height: 30,
                Width: 578,
              },
            ],
          },
        ],
        eStampPayload: {
          loan: {
            loanno: "",
            snctnno: "",
            regType: "4",
            signFlag: "1",
            estampFlag: "Y",
            f2f: "Y",
            state: stampingData.location,
            estampdtls: [
              {
                firstparty: contractData.company_Name,
                secondparty: contractData.vendor_Name,
                stampDutyAmount: stampingData.stampingDuty,
                considerationPrice: stampingData.contractValue,
                descriptionofDocument: stampingData.ContractNature,
                stampdutyPaidby: stampingData.stampingDutyPurchased,
                articleCode: stampingData.article || "004",
              },
            ],
            prtcptentty: signingAuthorities.map((data: any, index: number) => ({
              prtcptenttyId: (index + 1).toString(),
              fulnm: data.name,
              cntrprtycntnm: data.name,
              cntrprtyaddr: null,
              reltocntrct: "",
              emlid: data.email,
              cntrprtycntmobno: data.mobileNo.toString(),
              panno: "ALAPH7226A",
              ovdtype: "",
              ovdid: "",
              doi: "2005-12-28",
              lglcnstn: "Resident Individual",
              partytyp: "Resident Individual",
            })),
            loandtls: null,
            scrtydtls: null,
          },
        },
      };
    } else {
      payload = {
        Name: "Clife",
        EmailId: "sukrutha.kini@clife.ai",
        // referenceNo: "CT-8072",
        referenceNo: workFLowInstanceData?.contract_Ref_Number,
        DonotSendCompletionMailToParticipants: false,
        DoNotNotifyCustomer: false,
        SignatoryEmailIds: signingAuthorities.map((data) => data.email),
        SignatureSettings: [
          {
            ModeOfSignature: "3",
            ModeOfAuthentication: "2",
            CaptureSelfie: false,
            VerifyDemographicData: false,
          },
          {
            ModeOfSignature: "3",
            ModeOfAuthentication: "2",
            CaptureSelfie: false,
            VerifyDemographicData: false,
          },
        ],
        lstDocumentDetails: [
          {
            TemplateId: 0,
            DocumentName: `${contractData.contract_Tempate_Name}.pdf`,
            // DocumentName: "Zlendo_Technologies_Pvt_Ltd_Thomas_Cook_India_Ltd_Web_Development_Services.pdf",
            FileData: "",
            // ControlDetails: signingAuthorities.map((signee, index) => ({
            //   SearchText: `Signee_${signee.name}`,
            //   Anchor: "Top",
            //   AssignedTo: index + 1,
            //   ControlID: 4,
            // })),
            ControlDetails: [
              {
                PageNo: 1,
                ControlID: 4,
                AssignedTo: 1,
                Left: 15,
                Top: 92,
                Height: 32,
                Width: 135,
              },
              {
                PageNo: 1,
                ControlID: 4,
                AssignedTo: 2,
                Left: 458,
                Top: 90,
                Height: 30,
                Width: 578,
              },
            ],
          },
        ],
        eStampType: stampingData.stampingType,
        State: stampingData.location,
        // state: "Karnataka",
        Denomination: parseInt(stampingData.stampingDuty),
      };
    }
    let signingPayload = await dispatch(saveSigningAuthority(payload)).unwrap();
    if (signingPayload && signingPayload.value && signingPayload.value.isSuccess) {
      dispatch(updateToaster({ isToaster: true, toasterMsg: signingPayload.value.message, isTosterFailed: false }));
      dispatch(getSigningStatus({ contractNo: isEditData?.Assessment_Number }));
      setActivestepCount(activestepCount + 1);
    } else {
      dispatch(updateToaster({ isToaster: true, toasterMsg: signingPayload.messages[0], isTosterFailed: true }));
    }
    dispatch(updateLoader(false));
  };

  const handleFinalSubmit = async (back = false) => {
    if (activestepCount === 0) {
      const signingAuthorities = [...signingAuthority.Company, ...signingAuthority.Vendor];
      dispatch(
        saveTempSigningAuthorities(
          signingAuthorities.map((data, index) => ({
            contractor_Detail_Id: 0,
            contract_Ref_Number: isEditData?.Assessment_Number,
            name: data.name,
            designation: data.designation,
            emailId: data.email,
            mobile: data.mobileNo.toString(),
            type: index < signingAuthority.Company.length ? "company" : "vendor",
          }))
        )
      );
      setActivestepCount(1);
    }
    if (activestepCount === 1) {
      if (back) setActivestepCount(0);
    }
    if (activestepCount === 2) {
      let signingPayload = await dispatch(sendReminder({ contractNo: isEditData?.Assessment_Number })).unwrap();
      if (onClose) {
        onClose();
      }
    }
    // let request = {
    //   workflowInstanceId: isEditData?.Workflow_InstanceId,
    //   processInstanceId: isEditData?.Id,
    //   conditionValue: contractData?.vendor_Contract_Id,
    //   projectId: isEditData?.ProjectId,
    //   updatedBy: userLoginResponse ? userLoginResponse.UserName : "",
    // };
    // let data = await dispatch(updateCmWorkFlowInstance(request)).unwrap();
    // if (onClose) {
    //   onClose();
    // }
  };

  const setUpdatedTemplatedId = (updated: boolean) => {
    setIsTemplateUpdated(updated);
  };

  const updateWorkFLowInstance = async () => {
    let request = {
      workflowInstanceId: isEditData?.Workflow_InstanceId,
      processInstanceId: isEditData?.Id,
      conditionValue: contractData?.vendor_Contract_Id,
      projectId: isEditData.ProjectId,
      updatedBy: userLoginResponse ? userLoginResponse.UserName : "",
      DepartmentId: userLoginResponse ? String(userLoginResponse.Department) : "",
    };
    let data = await dispatch(updateCmWorkFlowInstance(request)).unwrap();
    if (data) {
      // dispatch(
      //   updateToaster({
      //     isToaster: true,
      //     toasterMsg: ToasterMsg.message,
      //     isTosterFailed: ToasterMsg.status !== "Success" ? true : false,
      //   })
      // );
      dispatch(updateLoader(false));
      onClose();
    }
  };

  const handleComplete = async () => {
    dispatch(updateLoader(true));
    const documentData = await dispatch(
      downloadWorkflowDocument({ contractNo: isEditData?.Assessment_Number })
    ).unwrap();
    const payload = {
      name: "Clife",
      EmailId: "sukrutha.kini@clife.ai",
      referenceNo: workFLowInstanceData?.contract_Ref_Number,
      donotSendCompletionMailToParticipants: true,
      doNotNotifyCustomer: true,
      workflowType: 0,
      redirectURL: "string",
      signatoryEmailIds: signingStatus?.eSignWorkFlowResponse[0]?.signatories?.map((data: any) => data.emailID) || [],
      lstDocumentDetails: [
        {
          documentName: signingStatus?.eSignWorkFlowResponse[0].documentName,
          fileData: documentData?.eSignDownloadResponse[0]?.fieldLists[0]?.base64FileData || "",
          controlDetails: [
            {
              PageNo: 1,
              ControlID: 4,
              AssignedTo: 1,
              Left: 2,
              Top: 250,
              Height: 190,
              Width: 122,
            },
            {
              PageNo: 2,
              ControlID: 4,
              AssignedTo: 2,
              Left: 2,
              Top: 250,
              Height: 190,
              Width: 122,
            },
          ],
        },
      ],
    };
    const data = await dispatch(initiateEmbedSign(payload)).unwrap();
    if (data) {
      updateWorkFLowInstance();
    }
    dispatch(updateLoader(false));
  };

  const  converToPDF= async() =>{
    if(isSystemVaraible){
      if(isTemplateUpdated){
        try {
          let payload = {
            userId: userLoginResponse?.UserId,
            fileKey: localStorage.getItem("doc_Key"),
            fileName: `${contractData?.contract_Tempate_Name}.docx`,
          };
          const response = await dispatch(convertToPDF(payload)).unwrap();
          if(response){
            dispatch(updateHeaderMenu(true));
            dispatch(updateMenu(true));
          }else{
            dispatch(updateHeaderMenu(true));
            dispatch(updateMenu(true));
          }       
          // window.parent.parent.postMessage(JSON.stringify(tempID), "*");
          // document.getElementById("vendor-container").style.display = "none";
          // document.getElementById("Toaster-container").style.display = "block";
          // replacedText =0;
        } catch (error) {
          dispatch(updateHeaderMenu(true));
          dispatch(updateMenu(true));
          console.error("Error fetching data:", error);
        }
      }else{
        dispatch(updateHeaderMenu(true));
          dispatch(updateMenu(true));
      }      
    }else{
        dispatch(updateHeaderMenu(true));
        dispatch(updateMenu(true));
    }    
  }

  return isSystemVaraible || viewContract ? (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "left", mb: 2 }}>
        <CustomButton
          variant="outlined"
          name={"Back"}
          onClick={async () => {
            setIsSystemVaraible(false);
            setViewContract(false);
            converToPDF();
          }}
          size="small"
        />
      </Box>
      {viewContract ? (
        <OnlyOfficeTextEditor
          fileName={`${contractData.contract_Tempate_Name}.pdf`}
          editorsMode={"view"}
          isOpenDocument={true}
        />
      ) : (
        <OnlyOfficeTextEditor
          fileName={`${contractData.contract_Tempate_Name}.docx`}
          editorsMode={"edit"}
          isOpenDocument={true}
          setUpdatedTemplatedId={setUpdatedTemplatedId}
        />
      )}
    </Box>
  ) : (
    <Box sx={{ width: "100%", px: 2, height: "calc(100vh - 180px)", overflow: "auto" }}>
      <Stack pt={2} justifyContent="space-between" direction="row">
        <Stack direction="row" alignItems="center" spacing={2}>
          <ChevronLeftIcon sx={{ cursor: "pointer" }} onClick={() => (onClose ? onClose() : "")} />
          <Typography variant="h6" color={"text.primary"} fontWeight={700}>
            {isEditData && isEditData?.ProcessName}
          </Typography>
        </Stack>
        <CustomButton
          variant="outlined"
          color="secondary"
          disabled={!(activestepCount === 1 && isTemplateUpdated !== false)}
          name={"View Contract"}
          onClick={() => setViewContract(true)}
        />
      </Stack>
      <Box>
        <Typography fontWeight="bold" textAlign="left" sx={{ marginTop: 4, marginBottom: 3, color: "#6a6a6a" }}>
          CONTRACT DETAILS
        </Typography>
        <Stack spacing={8} direction="row">
          {Object.entries(contractBasicData).map(([label, value]: any) => (
            <Box key={label}>
              <Typography variant="subtitle2" textAlign="left" fontWeight="light" fontSize="12px">
                {label}
              </Typography>
              <Typography textAlign="left" fontWeight="bold">
                {value}
              </Typography>
            </Box>
          ))}
        </Stack>
        {/* <Stack direction="row" justifyContent="space-between" mt={4}>
          <Box display="flex" alignItems="center" gap={4}>
            <Typography fontWeight="bold">Contract Summary</Typography>
            {expandSummary ? (
              <ExpandLess onClick={() => setExpandSummary(false)} sx={{ cursor: "pointer" }} />
            ) : (
              <ExpandMore onClick={() => setExpandSummary(true)} sx={{ cursor: "pointer" }} />
            )}
          </Box>
        </Stack>
        <Box display={expandSummary ? "block" : "none"}>
          <Typography textAlign="left" mt={2}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla.
          </Typography>
        </Box> */}
        <Divider sx={{ my: 4 }} />
        {activestepCount !== 2 && (
          <Box
            sx={{
              maxWidth: 300,
              pb: 3,
            }}
          >
            <CustomStepper activeStep={activestepCount} steps={main_steps} />
          </Box>
        )}

        {activestepCount === 0 &&
          Object.keys(signingAuthority).map((authority, index) => (
            <SigningAuthority
              key={`${authority}-${index}`}
              authority={authority}
              tableData={signingAuthority[authority]}
              view={false}
              handleDataChange={handleDataChange}
            />
          ))}
        {activestepCount === 1 && (
          <StampingDetails
            control={control}
            errorDetailsForm={errorDetailsForm}
            watch={watch}
            contractData={contractData}
            setValue={setValue}
          />
        )}
        {activestepCount === 2 && SigningAuthorityDetail()}
      </Box>
      <Stack
        direction="row"
        spacing={2}
        mt={4}
        pt={2}
        justifyContent="space-between"
        borderTop="1px solid #0000001f"
        position="absolute"
        bottom="40px"
        width="calc(100% - 120px)"
      >
        {activestepCount > 0 ? (
          <Stack direction="row" spacing={2}>
            <Box>
              <Typography fontWeight="bold">
                E-Sign Status: <span style={{ color: "#f1c867" }}>Pending</span>
              </Typography>
            </Box>
            <Box>
              <Typography fontWeight="bold">
                Stamping Status: <span style={{ color: "#f1c867" }}>Pending</span>
              </Typography>
            </Box>
          </Stack>
        ) : (
          <div></div>
        )}
        <Stack direction="row" spacing={2}>
          {activestepCount === 1 && (
            <CustomButton
              variant="outlined"
              name={"Back to E-Sign"}
              disabled={true}
              startIcon={<ChevronLeftIcon />}
              onClick={() => handleFinalSubmit(true)}
            />
          )}
          {activestepCount === 1 && (
            <CustomButton
              variant="contained"
              name={"Finalize PDF"}
              disabled={isTemplateUpdated}
              onClick={() => {
                replaceSystemVariable();
              }}
            />
          )}
          {activestepCount === 2 && signCompleted ? (
            <CustomButton
              variant="contained"
              name="Complete"
              onClick={() => {
                handleComplete();
              }}
            />
          ) : (
            <CustomButton
              variant="contained"
              name={
                activestepCount === 0
                  ? "E-Stamping Details"
                  : activestepCount === 1
                  ? "Proceed for E-Sign & E-Stamping"
                  : activestepCount === 2
                  ? "Send Reminder"
                  : "Send for E-Sign"
              }
              disabled={
                activestepCount !== 2 &&
                (signingAuthority.Company.length === 0 ||
                  signingAuthority.Vendor.length === 0 ||
                  (activestepCount === 1 && isTemplateUpdated === false))
              }
              endIcon={<ChevronRightIcon />}
              // onClick={handleSubmit(handleNextStep)}
              onClick={() => {
                if (activestepCount === 1) {
                  handleSubmit(handleNextStep)();
                  return;
                }
                handleFinalSubmit();
              }}
            />
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default NotifySigningAuthority;
