import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Tooltip, Box } from '@mui/material';
import CustomTextField from '../../../common/dynamicInputs/CustomTextField'; // Assume CustomTextField is another component
import BasicTableStyle from "../../../common/dynamicDataGrid/basicTable.module.scss";
import CustomMultilineTextField from '../../../common/dynamicInputs/CustomMultilineTextField';
import { SlaDetailsContext } from './PMResponse';
import CustomNumberField from '../../../common/dynamicInputs/CustomNumberField';
import CustomDatePicker from '../../../common/dynamicInputs/CustomDatePicker';
import { AQMConfig, PM_MeasuredIn, PMWorflowPages } from '../../../config/config';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { ACTIVE } from '../../../utils/constants';


interface DataTableProps {
  PageName : any ;
  view? : boolean
}


const PRSlaDetailsTable: React.FC<DataTableProps> = ({

  PageName ,
  view
}) => {
 const Requirementcontext = useContext(SlaDetailsContext);
 const {
  slaList,
  setSlaList,
  validationErrors,
  setvalidationErrors
} = Requirementcontext!;
    const performanceManagementReducer: any = useSelector((state: RootState) => state?.performanceManagementReducer);
const {  ThresholdTable }: any = performanceManagementReducer.ThresholdMasterDataSlice;
      const {  SeverityMasterTable }: any = performanceManagementReducer.SeverityMasterDataSlice;
const [ThresholdValue , setThresholdValues] = useState<any[]>([])
const [SeverityValue , setSeverityValues] = useState<any[]>([])

useEffect(() => {
 
  let filtersThreshold : any[] = ThresholdTable?.filter((item: any) => item?.isActive == "Active" ) || []
  setThresholdValues(filtersThreshold)
},[ThresholdTable])

useEffect(() => {

  console.log(SeverityMasterTable , "SeverityMasterTable");
  
  let filteredSeverity : any[] = SeverityMasterTable?.filter((item: any) => item?.isActive == "Active" ) || []
  setSeverityValues(filteredSeverity)

},[SeverityMasterTable])
 
  const headers = useMemo(
    () => [
        { label: "Sr.", condition: true ,   render: (row: any, index: any) => (
          <Box
            sx={{
              height: "18px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {index + 1}
          </Box>
        )
      },
        { label: "SLA", condition: true ,   render: (row: any) => row?.sla, },
        { label: "Measured In", condition: true  ,   render: (row: any) => row?.measured_In_Name, },
        { label: "Severity", condition: true ,   render: (row: any) => row?.severity_Of_SLA_Name, },
        { label: "Target Min", condition: true ,   render: (row: any) => row?.target_Min, },
        { label: "Target Max", condition: true ,   render: (row: any) => row?.target_Max,},
       
        
        { label: "Actual", condition: PageName == PMWorflowPages?.VendorPortal  ,   render: (row: any , index : any) => ( <>
          { PageName == PMWorflowPages?.VendorPortal  ?  (
            <>
             {  row.measured_In_Name != PM_MeasuredIn.Date ?
                <CustomNumberField
                  label={""}
                  name={"target_Min"}
                  value={row?.actual}
                  isDecimal={true}
                  required={true}
                  onChange={(e) => {ChangeValueinRequirement(e , index)}}
                  error={Boolean(validationErrors?.[`${index}-actual`])}
                  helperText={
                    validationErrors?.[`${index}-actual`] &&
                    validationErrors?.[`${index}-actual`]?.toString()
                  }
                />
             :  
                      <CustomDatePicker
                        maxDate={dayjs().add(100, "year").toDate()}
                        label={""}
                        value={row?.actual ? dayjs(row?.actual) : null}
                        required={true}
                        readOnly={view}
                        onChange={(e) => {ChangeValueinRequirement(e , index)}}
                        error={Boolean(validationErrors?.[`${index}-actual`])}
                        helperText={
                    validationErrors?.[`${index}-actual`] &&
                    validationErrors?.[`${index}-actual`]?.toString()
                  }
                      />
                }
            </>
          ) : (
            <> {  row.measured_In_Name == PM_MeasuredIn.Date ? (row?.actual ? dayjs(row?.actual).format("DD/MM/YYYY") : "" ) : row?.actual || ""  }</>
          )}
        </>), },
        { label: "Score %", condition: true ,   render: (row: any) => row?.score  },
        { label: "Tolerance Level", condition: (PageName == PMWorflowPages?.VendorPortal || PageName == PMWorflowPages?.CXO)   ,   render: (row: any) => row?.tolerance_Level },
       { label: "Remarks", condition: true ,   render: (row: any) => row?.remark, },
    ],
    [validationErrors]
);

 const activeHeaders = useMemo(
    () => headers.filter((header) => header.condition),
    [headers] // Recompute only if headers change
  );

  const calculateDaysDifference = (startDate: string, endDate: string): number => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffInMilliseconds = end.getTime() - start.getTime();
    const daysDifference = diffInMilliseconds / (1000 * 60 * 60 * 24);
  
    return Math.floor(daysDifference) ;
  };
  const calculateScore = (actual : any, selectedValue : any ) => {
   let measeredIn =  selectedValue?.measured_In_Name;
   let best : number = 0
   let Threshold : number = 0
   let Deviation : number = 0
   let Seviarity : number = SeverityValue?.find((item : any) => item?.severityId == selectedValue?.severity_Of_SLA )?.score || 0  
 
   if(actual){
    if(measeredIn == PM_MeasuredIn.Date){

      let targetValue = calculateDaysDifference(selectedValue?.target_Min , selectedValue?.target_Max);
      let actualValue = calculateDaysDifference(selectedValue?.target_Min , actual);

      console.log(actualValue , targetValue );
      
      best = (1 - (actualValue - targetValue) / actualValue) * 100;
    }else if(measeredIn == PM_MeasuredIn.Percentage){
      let targetValue = Number(selectedValue?.target_Min || 0)
      best = (1 - (targetValue - actual) / actual) * 100;
    }else{
      let targetValue = Number(selectedValue?.target_Max || 0)
      best = (1 - (actual - targetValue) / actual) * 100;
    }
         let findedThreshold = ThresholdValue.find((item : any) => item?.fromRange <= best && item?.toRange >= best)

         if(findedThreshold && findedThreshold?.score){
              Threshold = Number(findedThreshold?.score);
         }

         let ActualScore = Number(Seviarity) * Threshold;
         let BestScore = Number(Seviarity) * Number(selectedValue?.bestScore);
         let Deviation = Number(BestScore) - Number(ActualScore) ;
          console.log(best , Threshold , "Deviation :" , Deviation , "ActualScore :" , ActualScore , "BestScore :" , BestScore  );
          
         return {best : best.toFixed(2)  , Threshold : Threshold , Deviation : Deviation  }
   }else{
       return {best : best  , Threshold : Threshold , Deviation : Deviation }
   }
   


   
  };
  const ChangeValueinRequirement = (value: any, index: any ) => {
    if(slaList && setSlaList){
      setSlaList((prevList: any[]) => {
        const updatedList = [...prevList];
      
        if (updatedList[index]) {
          let actualValue = Number(value || 0)
          let selectedValue :any = updatedList[index]
          let {best   , Threshold , Deviation } = calculateScore(actualValue  , selectedValue);
          updatedList[index] = {
            ...updatedList[index],
            actual : value,
            score : best ,
            Threshold : Threshold,
            Deviation : Deviation,
          };
        }
        return updatedList;
      });
    }
  
  };
  return (
    <TableContainer style={{ border: "1px solid #f1f1f1", borderRadius: "5px", maxHeight: "50vh" }}>
    <Table stickyHeader aria-label="simple basic table">
      <TableHead>
        <TableRow>
          {activeHeaders.map((header, index) => (
            <TableCell key={index} className={`${BasicTableStyle.tab_header}`}>
              {header.label} {/* Renders the label string */}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {slaList && slaList.length > 0 ? (
          slaList.map((row: any, rowIndex: number) => (
            <TableRow key={`Row_${rowIndex}`} className={BasicTableStyle.alternateRow}>
              {activeHeaders.map((header, colIndex) => (
                <TableCell key={`Cell_${rowIndex}_${colIndex}`} className={`${BasicTableStyle.tab_data}`}>
                  {header.render ? header.render(row, rowIndex) : null} {/* Safeguards invalid render */}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={activeHeaders.length} className={`${BasicTableStyle.tab_data}`} align="center">
              No data available
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
  
  );
};

export default PRSlaDetailsTable;
