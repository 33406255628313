import React, { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import PageMainHeading from "../../../common/CommonHeadings/PageMainHeading";
import AddClauseTypeMaster from "./AddClauseTypeMaster";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { getDepartmentHistory } from "../../../store/slices/departmentData";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import {
  activeInactiveClauseMaster,
  getClauseMasterTypeList,
  updateClauseMasterDetailsAddandEdit,
} from "../../../store/contractManagement/slices/clauseMasterSlice";
import { ACTIVE, INACTIVE } from "../../../utils/constants";
import { IMenu } from "../../../models/loggin.type";
import { RecordLockRelease } from "../../recordLock/CommonRecordLock";

const ClauseTypeMaster: React.FC = () => {
  const [permissionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const [isAddNewClauseType, setIsAddNewClauseType] = useState<boolean>(false);
  const [isEditData, setIsEditData] = useState<any>({});
  const [clauseDetails, setClauseDetails] = useState<any[]>([]);
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isDeleteClause, setIsDeleteClause] = useState(false);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const [option, setOption] = useState("");
console.log(permissionPage,"permissionPage")
  const dispatch = useAppDispatchThunk();
    const { handleRecordLockUpdate } = RecordLockRelease();
  

  const contractManagementReducer: any = useSelector((state: RootState) => state?.contractManagementReducer);
  const {
    clauseMasterDetailsData,
    isclauseMasterDetailsLoading,
    isclauseMasterDetailsAdded,
    isclauseMasterDetailsUpdated,
    isclauseMasterDetailsDeleted,
    ToasterMsg,
  }: any = contractManagementReducer.clauseMasterData;
  const { userLoginResponse, currentPagePermissionData, user } = useSelector((state: RootState) => state.loggerData);

  const { currentpageTitleData } = useSelector((state: RootState) => state.FormTitleMasterData);

  const columns: any = {
    table_name: "Clause Type",
    isAction: true,
    columns: [
      { Field: "clauseType_Name", FieldType: "string", DisplayName: "Clause Type" },
      { Field: "isServiceMappingRequired", FieldType: "string", DisplayName: "Service Mapping Needed" },
      { Field: "isSMEReviewApplicable", FieldType: "string", DisplayName: "SME Review Applicable" },
      { Field: "IsActive", FieldType: "string", DisplayName: "Status" },
      { Field: "updated_By", FieldType: "string", DisplayName: "Modified By" },
    ],
  };

  useEffect(() => {
    if (clauseMasterDetailsData?.length > 0) {
      const updatedData = clauseMasterDetailsData.map((clause: any) => ({
        ...clause,
        isServiceMappingRequired: clause.isServiceMappingRequired ? "Yes" : "No",
        isSMEReviewApplicable: clause.isSMEReviewApplicable ? "Yes" : "No",
        IsActive: clause.isActive,
      }));
      setClauseDetails(updatedData);
    }
  }, [clauseMasterDetailsData]);

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  const handleApiCall = () => {
    dispatch(getClauseMasterTypeList());
  };
  useEffect(() => {
    handleApiCall();
  }, []);

  const handleOpen = async (data: any, option: string) => {
    const updatedData = clauseMasterDetailsData?.find((i: any) => i.clauseType_Id === data.clauseType_Id);
    if (!updatedData) return;
    setOption(option);

    switch (option) {
      case "delete":
        setIsDeleteData(updatedData);
        setIsDeleteClause(true);
        break;
      case "history":
        setIsVersionHistory(true);
        setIsEditData(updatedData);
        dispatch(getDepartmentHistory({ departmentId: data.ClauseTypeId, formID: 7 }));
        break;
      case "view":
        setIsEditData(updatedData);
        setIsEdit(true);
        break;
      case "edit":
        setIsEditData(updatedData);
        dispatch(updateLoader(true));
        const response = { data: true };
        if (response.data) {
          dispatch(updateLoader(false));
          setIsEdit(true);
        } else {
          dispatch(updateLoader(false));
        }
        break;
      default:
        setIsEdit(true);
        break;
    }
  };

  const deleteClauseMaster = () => {
    const inactiveFormData = {
      isActive: isDeleteData?.isActive === ACTIVE ? false : true,
      updated_By: userLoginResponse?.UserName,
      updated_On: new Date().toISOString(),
      approved_By: userLoginResponse?.UserName,
      approved_On: new Date().toISOString(),
      clauseType_Id: isDeleteData.clauseType_Id,
      clauseType_Name: isDeleteData.clauseType_Name,
      isServiceMappingRequired: isDeleteData.isServiceMappingRequired,
      isSMEReviewApplicable: isDeleteData.isSMEReviewApplicable,
      actionTaken: isDeleteData?.isActive === ACTIVE ? INACTIVE : ACTIVE,
      remarks: "",
      moduleName: "Clause Type Master",
      activity: isDeleteData?.isActive === ACTIVE ? INACTIVE : ACTIVE,
      createdBy: user?.UserName,
    };
    dispatch(activeInactiveClauseMaster(inactiveFormData));
  };
  useEffect(() => {
    const handleFunc = () => {
      if (isclauseMasterDetailsAdded || isclauseMasterDetailsUpdated || isclauseMasterDetailsDeleted) {
        dispatch(updateClauseMasterDetailsAddandEdit(false));
       handleRecordLockUpdate();
        
        dispatch(
          updateToaster({
            isToaster: true,
            toasterMsg: ToasterMsg?.Message,
            isTosterFailed: ToasterMsg?.Status === "false" ? true : false,
          })
        );
        handleApiCall();
        setIsDeleteClause(false);
      }
    };
    handleFunc();
  }, [isclauseMasterDetailsAdded, isclauseMasterDetailsUpdated, isclauseMasterDetailsDeleted]);
  return (
    <Box sx={{ width: "100%" }}>
      <Stack direction="column" spacing={2}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
          {/* <PageMainHeading title="Clause Type" /> */}
          {permissionPage && <PageMainHeading title={permissionPage?.MenuName} />}
        </Box>
        <Box sx={{ width: "100%" }}>
          <MaterialDynamicGrid
            data={columns}
            rows={clauseDetails}
            loading={isclauseMasterDetailsLoading}
            handleAction={handleOpen}
            handleAdd={() => setIsAddNewClauseType(true)}
            enableZoomIcon={false}
            showhistory={false}
            showAdd={permissionPage?.IsAdd === 1}
            showDelete={permissionPage?.IsDelete === 1}
            showView={permissionPage?.IsView === 1}
            showEdit={permissionPage?.IsEdit === 1}
            showExports={permissionPage?.IsExport === 1}
            userId={userLoginResponse?.UserId || 0}
              isEnableRecordLock = {{ IdField : "clauseType_Id" , data :  {   moduleName : currentPagePermissionData?.MenuName || "",
                              // FormID : FormIdConfig?.departmentFormId ,
                              MenuID : currentPagePermissionData?.MenuId,
                              IsCheckPending : false  }}}
            pageName="Clause Type"
          />
        </Box>
      </Stack>

      <CustomDialog
        show={isAddNewClauseType}
        onHide={() => setIsAddNewClauseType(false)}
        maxWidth="md"
        header={`Add ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Clause Type"}`}
        contentNode={<AddClauseTypeMaster onClose={() => setIsAddNewClauseType(false)} />}
      />

      <CustomDialog
        show={isEdit}
        onHide={() => {setIsEdit(false)
          handleRecordLockUpdate()
        }
      }
        maxWidth="md"
        header={
          option === "view"
            ? `View ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Clause Type"}`
            : `Update ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Clause Type"}`
        }
        contentNode={
          <AddClauseTypeMaster
            existingData={isEditData}
            isView={option === "view"}
            isEdit={isEdit}
            onClose={() =>{ handleRecordLockUpdate();
              setIsEdit(false)}}
          />
        }
      />

      <ModalPopUp
        onDeleteMethod={deleteClauseMaster}
        isDelete
        onHide={() => setIsDeleteClause(false)}
        show={isDeleteClause}
        header="Confirm"
        text={`Are you sure you want to ${
          isDeleteData?.isActive === ACTIVE ? "deactivate" : "activate"
        } the selected Clause Type ?`}
        ActionName={isDeleteData?.isActive === ACTIVE ? INACTIVE : ACTIVE}
      />
    </Box>
  );
};

export default ClauseTypeMaster;
