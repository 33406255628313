import { Box, Grid, Typography } from "@mui/material";
import { textFieldWidth } from "../../../utils/constants";
import LableandValuesPreview from "../../../common/CommonPreview/LableandValuesPreview";



interface ContractDetailsprops {
    data: any;
 
}

export const ContractDetails: React.FC<ContractDetailsprops> = ({ data }) => {

return(
    
    <>
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Typography
              sx={{
                height: "12px",
                color: "#959595",
                display: "block",
                fontSize: "15px",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              CONTRACT DETAILS
            </Typography>
          </Box>
          <Box>
            <Grid container spacing={2}>
              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <LableandValuesPreview lable="Company Name" Value={`${data?.companyName}`} />
              </Grid>
              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <LableandValuesPreview lable="Third Party Name" Value={`${data?.vendorName}`} />
              </Grid>
              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              ></Grid>
              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <LableandValuesPreview lable="Contract Number" Value={`${data?.contractRefNumber}`} />
              </Grid>
              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <LableandValuesPreview lable="Parent Contract Number" Value={`-`} />
              </Grid>
              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <LableandValuesPreview lable="PR Reference Number" Value={`${data?.pM_Ref_Number || "-"}`} />
              </Grid>
            </Grid>
          </Box>
    
    </>
)

}