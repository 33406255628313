import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState, useAppDispatch } from "../../store/store";
import CustomSucessBanner from "../../common/dynamicMessageBanner/CustomSucessBanner";
import { PMResponse } from "../../components/performanceManagement/prReviews/PMResponse";
export const PMVendorRespondor: React.FC = () => {
    const dispatch = useAppDispatch();

    const { isVendorAuthenticated  , isLoading , vendorLoginDetails } = useSelector((state: RootState) => state.loggerData);
    const [currentPage,setcurrentPage] = useState<any>("")
  
    const { vendor_persional_Id , event_Id , instance_Details_Id } = useParams();



     return(
        <>
          {currentPage === "" ? ( <Box sx={{p:isVendorAuthenticated?2:""  }} >
            <PMResponse PageName={"Vendor Portal"}/>
        </Box> ) : null }
        {currentPage === "Page sucess" ? (
        <CustomSucessBanner label="Your Response is completed and submitted successfully ! you will hear from us soon!"/>
       
      ) : null}
        </>
     )
}