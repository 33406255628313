import { Box, Grid, Stack, Typography, Divider, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Tooltip, IconButton, Collapse } from "@mui/material";
import React, { createContext, useEffect, useMemo, useState } from "react";
import CustomBreadcrumbs from "../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import PageMainHeading from "../../../common/CommonHeadings/PageMainHeading";
import LableandValuesPreview from "../../../common/CommonPreview/LableandValuesPreview";
import { textFieldWidth } from "../../../utils/constants";
import PageInnerHeading from "../../../common/CommonHeadings/PageInnerHeading";
import BasicTableStyle from "../../../common/dynamicDataGrid/basicTable.module.scss";
import { useTheme, } from '@mui/material/styles'
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import CustomMultilineTextField from "../../../common/dynamicInputs/CustomMultilineTextField";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import CustomSvg from "../../../common/CustomSvg";
import gridStyle from "../../../common/dynamicDataGrid/dynamicgrid.module.scss";
import { ContractDetails } from "../PMCommonDetails/ContractDetails";
import { RequirementDetails } from "../PMCommonDetails/RequirementDetails";
import PRSlaDetailsTable from "./PRSlaDetailsTable";
import { PMWorflowPages } from "../../../config/config";
import { getAllThresholdMaster } from "../../../store/performanceManagement/slices/thresholdMaster";
import { useAppDispatch } from "../../../store/store";
import { getAllSeverityMaster } from "../../../store/performanceManagement/slices/severityMaster";
export interface PMResponseProps {
    view?: any;
    edit?: any;
    isEditData?: any;
    onClose?: () => void;
    PageName: any;
}


interface slaContextProps {

  slaList: any[];
  validationErrors: any;
  setSlaList: (value : any) => void;
  setvalidationErrors: (value: any) => void;
}

export const SlaDetailsContext = createContext<slaContextProps | undefined>(undefined);
export const PMResponse: React.FC<PMResponseProps> = ({
    view,
    edit,
    isEditData,
    onClose,
    PageName,
}) => {
  const dispatch = useAppDispatch();
    const theme = useTheme();
    const [Remark, setRemark] = useState('')
    const [contractdetails, setcontractdetails] = useState<any>({});
    const [validationErrors, setvalidationErrors] = useState<any>({});
    const [finalRating, setfinalRating] = useState<any>(0);
    const [slaList, setSlaList] = useState([
        {
            "pR_Clause_SLA_Id": 157,
            "pR_Clause_Target_Id": 254,
            "sla": "sla value new k",
            "measured_In": 383,
            "measured_In_Name": "Hours",
            "target_Min": "1",
            "target_Max": "4",
            "tolerance_Level": "2",
            "bestScore": "2",
            "severity_Of_SLA": 34,
            "severity_Of_SLA_Name": "Medium",
            "remark": "ok ok",
            "actual": "",
            "score": "",
            "isActive": true,
            "updatedBy": "Karthick A",
            "updatedOn": "2025-01-20T04:46:15.307"
          },
          {
            "pR_Clause_SLA_Id": 158,
            "pR_Clause_Target_Id": 254,
            "sla": "sla value new j2",
            "measured_In": 383,
            "measured_In_Name": "Percentage",
            "target_Min": "98",
            "target_Max": "100",
            "tolerance_Level": "2",
            "bestScore": "2",
            "severity_Of_SLA": 35,
            "severity_Of_SLA_Name": "Medium",
            "remark": "ok ok 2",
            "actual": "",
            "isActive": true,
            "updatedBy": "Karthick A",
            "updatedOn": "2025-01-20T04:46:15.307"
          },
          {
            "pR_Clause_SLA_Id": 158,
            "pR_Clause_Target_Id": 254,
            "sla": "sla value new j3",
            "measured_In": 383,
            "measured_In_Name": "Date",
            "target_Min": "2024-04-01T00:00:00",
            "target_Max": "2024-07-30T23:59:59",
            "tolerance_Level": "2",
            "bestScore": "2",
            "severity_Of_SLA": 35,
            "severity_Of_SLA_Name": "Medium",
            "remark": "ok ok 2",
            "actual": "",
            "isActive": true,
            "updatedBy": "Karthick A",
            "updatedOn": "2025-01-20T04:46:15.307"
          }
    ]);
 
    useEffect(() => {
     dispatch(getAllThresholdMaster({
            FormID: 2
        }))
           dispatch(getAllSeverityMaster({
                            FormID: 2
                        }))
    },[])

useEffect(() => {
      
      if(slaList && slaList?.length > 0 && PageName ==  PMWorflowPages?.VendorPortal){
         if(PageName == PMWorflowPages.SOReview){
                 
                    
         }else{
            setfinalRating(0)
         }

      }

},[])


const calculateRating =() => {

}

    const RequirementDetaildata = { ClauseRefNumber: 1.1, ClauseHeader: "Payment milestone", Workflow: "Basic", Scopeoftarget: "Producti on Support", Deliverables: "Loerm Ipsum", SMEReview: "yes", Responder: "Third-party", ReviewPeriod: '01/09/2024', ReviewDueDate: '31/01/2025' }

    return (<>
        <Box sx={{ width: "100%" }}>
            <Stack spacing={2}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                    }}
                >
                    <Typography variant="h6" color={"text.primary"}>
                        {`${PageName}`}
                    </Typography>
                </Box>
               
                <ContractDetails data={contractdetails} />
                <Divider sx={{ border: '1px solid', color: "#e0e0e3" }} />
                <RequirementDetails data={RequirementDetaildata} />
                
                <Divider sx={{ border: '1px solid', color: "#e0e0e3" }} />

                <Box
                    sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2, width: "100%" }}>
                    <PageInnerHeading title={"SLA To Monitor"} />
                    {PageName == PMWorflowPages?.VendorPortal &&
                        <Box sx={{display : 'flex' , gap :1 }}>
                            <Box>
                            <Tooltip title="Export">
                                <IconButton onClick={() => { }}>
                                    <CustomSvg color={`${theme.palette.primary.main}`} size={18} name={"export"} />
                                </IconButton>
                            </Tooltip>
                            </Box>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Box>
                            <Tooltip title="Import">
                                <IconButton onClick={() => { }}>
                                    <CustomSvg color={`${theme.palette.primary.main}`} size={18} name={"import"} />
                                </IconButton>
                            </Tooltip>
                            </Box>
                        </Box>
                    }


                </Box>
                 <SlaDetailsContext.Provider
                              value={{
                                slaList,
                                validationErrors,
                                setSlaList,
                                setvalidationErrors
                              }}
                            >
                <PRSlaDetailsTable
                    PageName={PageName}
                />
                </SlaDetailsContext.Provider>
             
                { PageName != PMWorflowPages?.VendorPortal ?   <Box
                    sx={{
                        height: "100%",
                        width: "100%",
                    }}
                >
                 <Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <CustomMultilineTextField
                                label={"Remarks"}
                                value={Remark}
                                rows={3}
                                required={true}
                                onChange={(e) => {
                                    setRemark(e)
                                }}
                            // error={Boolean(remarkerror)}
                            // helperText={remarkerror}
                            />
                        </Grid>
                    </Grid>

                </Box> : null }
                {
                    PageName === PMWorflowPages?.VendorPortal ? <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2, width: "100%" }}>
                       
                        <Box sx={{ display: "flex", alignItems: "center", gap: 2, justifyContent: "flex-end", width :'100%' }}>
                         
                            <CustomButton
                                type="reset"
                                name={"Submit"}
                                onClick={() => {
                                    //  setselectedQuestionindex && setselectedQuestionindex(selectedQuestionIndex + 1)
                                }}
                            />
                        </Box>
                    </Box> :
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2, width: "100%" }}>
                            <CustomButton
                                type="reset"
                                variant="outlined"
                                disabled={false}
                                name={"Discard"}
                                onClick={() => {
                                    //   setselectedQuestionindex &&  setselectedQuestionindex(selectedQuestionIndex - 1);
                                }}
                            />
                            <Box sx={{ display: "flex", alignItems: "center", gap: 2, justifyContent: "space-between", marginLeft: 3 }}>
                              
                                <CustomButton
                                    disabled={Remark.length == 0}
                                    type="reset"
                                    name={"Accept"}
                                    onClick={() => {
                                        //  setselectedQuestionindex && setselectedQuestionindex(selectedQuestionIndex + 1)
                                    }}
                                />
                                <CustomButton
                                    disabled={Remark.length == 0}
                                    type="reset"
                                    name={"Reject"}
                                    onClick={() => {
                                        //  setselectedQuestionindex && setselectedQuestionindex(selectedQuestionIndex + 1)
                                    }}
                                />
                            </Box>
                        </Box>
                }
            </Stack>
        </Box>
    </>)
}