import { Box, Stack, Typography, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomButton from "../../common/dynamicButton/CustomButton";
import MaterialDynamicGrid from "../../common/dynamicDataGrid/MaterialDynamicGrid";
import { RootState, useAppDispatchThunk } from "../../store/store";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import { IMenu } from "../../models/loggin.type";
import { AddRecordLock, GetRecordLock, UpdateRecordLock } from "../../store/slices/recordLock";
import { updateLoader, updateToaster } from "../../store/slices/loader";
import {
  getCompanyList,
  getCompanyHistory,
  deleteCompany,
  updateCompanyAddandEdit,
} from "../../store/slices/companyMasterData";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";
import { getAllSaveandDraftList, updateSaveasDraftAddandEdit } from "../../store/slices/saveasdraft";
import { getLovList } from "../../store/slices/lovMasterData";
import { getIndustryList, getParentIndustryList } from "../../store/slices/industryMasterData";
import { getThirdPartyList } from "../../store/slices/thirdPartyData";
import { getLocationList } from "../../store/slices/locationMasterData";
import { getRegionalList } from "../../store/slices/regionalSettingsData";
import { HandleRecordLockCheck, RecordLockUpdate } from "../recordLock/RecordLock";
import { Edit_Enable_tosterMSG, FormIdConfig, LocationConfig, LovConfig } from "../../config/config";
import masterEditEnableService from "../../store/services/masterEditEnableService";
import PageMainHeading from "../../common/CommonHeadings/PageMainHeading";
import { ACTIVE, INACTIVE } from "../../utils/constants";
import CompanyMasterStepper from "./CompanyMasterStepper/CompanyMasterStepper";
import { RecordLockRelease } from "../recordLock/CommonRecordLock";

import dayjs from "dayjs";

export interface ICompanyMasterProps {
  handlePrevious?: () => void;
  handleNext?: () => void;
}

export const CompanyMasterMain: React.FC<ICompanyMasterProps> = ({ handlePrevious, handleNext }) => {
  const [permissionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const [addForm, setAddForm] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [Option, setOption] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [formDatas, setFormDatas] = useState<any[]>([]);
  const [IsEditData, setIsEditData] = useState<any[]>([]);
  const [initialData, setinitialData] = useState<any>({});
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const { user, userLoginResponse, currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);

  const { draftdata, isDraftAdded, isDraftUpdated, isDraftDeleted, DraftToasterMsg } = useSelector(
    (state: RootState) => state.saveAsDraft
  );
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
  const { CompanyTable, ToasterMsg, isCompanyAdded, isCompanyDeleted, isCompanyUpdated, versionHistory } = useSelector(
    (state: RootState) => state.CompanyMasterData
  );
  const [editId, setEditId] = useState<any>(0);
  const [getRecordLock, setGetRecordLock] = useState<any>([]);
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [DraftTableData, setDraftTableData] = useState<any[]>([]);
  const [CompanyTypelov, setCompanyTypelov] = useState<any[]>([]);
  const [CompanyConstitutionlov, setCompanyConstitutionlov] = useState<any[]>([]);
  const [jurisdiction, setJurisdiction] = useState<any[]>([]);
  const [getImageUrlDatas, setImageUrlDatas] = useState<string>("");
  const [LOBlov, setLOBlov] = useState<any[]>([]);
  const { handleRecordLockUpdate } = RecordLockRelease();

  const [versionHistoryData, getVersionHistoryData] = useState<any>();
  const { recordLock } = useSelector((state: RootState) => state.recordLock);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { industryTable, ParentindustryTable } = useSelector((state: RootState) => state.indutryMasterData);
  const { locationTable } = useSelector((state: RootState) => state.locationMasterData);
  const { thirdPartyData } = useSelector((state: RootState) => state.thirdPartyData);
  const { currentpageTitleData } = useSelector((state: RootState) => state.FormTitleMasterData);

  const columns_version: any = {
    table_name: "Company Master History Records",
    isAction: false,
    columns: [],
  };
  const dispatch = useAppDispatchThunk();
  useEffect(() => {
    if (versionHistory.CompanyVHeader.length > 0 && versionHistory.CompanyVHeader.length > 0) {
      if (versionHistory.CompanyVHeader && versionHistory.CompanyVHeader.length > 0) {
        let re_columns = {
          ...columns_version,
          columns: versionHistory.CompanyVHeader,
        };
        getVersionHistoryData(re_columns);
      }
    }
  }, [versionHistory]);

  useEffect(() => {
    setCompanyTypelov(
      lovTable.filter((item: any) => item.Name === LovConfig.Company_Type && item.IsActive === "Active") || []
    );

    setLOBlov(lovTable.filter((item: any) => item.Name === LovConfig.LOB && item.IsActive === "Active") || []);
    setCompanyConstitutionlov(
      lovTable.filter((item: any) => item.Name === LovConfig.Company_Constitution && item.IsActive === "Active") || []
    );
  }, [lovTable]);

  useEffect(() => {
    if (getLocationList.length > 0) {
      const filteredJurisdiction = locationTable.filter(
        (item: any) => item.LocationTypeValue === LocationConfig.Location_City
      );
      setJurisdiction(filteredJurisdiction.filter((item: any) => item.IsActive === "Active"));
    }
  }, [locationTable]);

  const callDraft = () => {
    const dataObj = {
      UserId: userLoginResponse?.UserId,
      ModuleName: "Company Master Draft",
      ScreenName: "Company Master Draft",
    };
    dispatch(getAllSaveandDraftList(dataObj));
  };

  const columns: any = {
    table_name: "Company",
    isAction: true,
    columns: [
      {
        Field: "Legal_Name",
        DisplayName: "Legal Name",
        FieldType: "string",
      },
      {
        Field: "Brand_Name",
        DisplayName: "Brand Name",
        FieldType: "string",
      },
      {
        Field: "Short_Name",
        DisplayName: "Short Name",
        FieldType: "string",
      },

      {
        Field: "Company_Type_Name",
        DisplayName: "Company Type",
        FieldType: "string",
      },
      {
        Field: "Parent_Company_Name_Value",
        DisplayName: "Parent Company Name",
        FieldType: "string",
      },
      {
        Field: "Group_Name",
        DisplayName: "Group Name",
        FieldType: "string",
      },
      {
        Field: "CIN",
        DisplayName: "CIN",
        FieldType: "string",
      },
      {
        Field: "TAN",
        DisplayName: "TAN",
        FieldType: "string",
      },
      {
        Field: "PAN",
        DisplayName: "PAN",
        FieldType: "string",
      },
      {
        Field: "Industry_Name_Value",
        DisplayName: "Industry",
        FieldType: "string",
      },

      {
        Field: "Constitution_Value",
        DisplayName: "Constitution",
        FieldType: "string",
      },
      {
        Field: "Jurisdiction_Value",
        DisplayName: "Jurisdiction",
        FieldType: "string",
      },
      {
        Field: "WebSite",
        DisplayName: "Website",
        FieldType: "string",
      },
      {
        Field: "Regulator_Name",
        DisplayName: "Regulator",
        FieldType: "string",
      },
      {
        Field: "IsActive",
        DisplayName: "Status",
        FieldType: "string",
      },
      {
        Field: "Updated_By",
        FieldType: "string",
        DisplayName: "Modified By",
      },
    ],
  };
  useEffect(() => {
    callDraft();
  }, []);
  useEffect(() => {
    dispatch(getLovList({ Name: "" }));
    dispatch(getIndustryList({ FormID: 2 }));
    dispatch(getParentIndustryList({ FormID: 2 }));
    dispatch(getThirdPartyList({ FormID: 0 }));
    dispatch(
      getLocationList({
        FormID: 2,
      })
    );
    dispatch(
      getRegionalList({
        FormID: 2,
      })
    );
    dispatch(
      getCompanyList({
        FormID: 2,
      })
    );
  }, []);

  //console.log(draftdata)
  useEffect(() => {
    if (draftdata && Array.isArray(draftdata)) {
      const modifiedData = draftdata
        .map((item: any) => {
          try {
            const jsonData = JSON.parse(item.DraftData);
            return {
              ...jsonData,
              ...jsonData.finalFormData,
              DraftStatus: item.DraftStatus,
              DraftId: item.DraftId,
              // IsActive: item.isActive === true ? ACTIVE : INACTIVE,
            };
          } catch (error) {
            console.error("Failed to parse DraftData:", error, item.DraftData);
            return null;
          }
        })
        .filter(Boolean);

      setDraftTableData(modifiedData);
    }
  }, [draftdata, isDraftAdded, isDraftUpdated]);

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);


  useEffect(() => {
    setDataTable(CompanyTable);
  }, [CompanyTable]);

  const deleteCompanyMaster = async () => {
    let formData = { ...isDeleteData };

    const jsonSector = isDeleteData?.Sector ? JSON.parse(isDeleteData?.Sector) : [];
    const Sector_name = jsonSector
      .map((data: any) => {
        const Industry = industryTable?.find((item: any) => item.IndustryId === data);
        return Industry?.IndustryName || "";
      })
      .filter(Boolean)
      .join(", ");

    const jsonCompanyLOB = isDeleteData?.Company_LOB ? JSON.parse(isDeleteData?.Company_LOB) : [];
    const LOB_name = jsonCompanyLOB.map((data: any) => {
      const Company_LOB = lovTable?.find((item: any) => item?.LovId === data);
      return Company_LOB?.Options || "";
    })      
    .filter(Boolean)
    .join(", ");

    const jsonDepartment = isDeleteData?.Department ? JSON.parse(isDeleteData?.Department) : [];
    const Department_name = jsonDepartment.map((data: any) => {
      const department = dataTable?.find((item: any) => item?.DepartmentId === data);
      return department?.DepartmentName || "";
    })
      .filter(Boolean)
      .join(", ");

    let JsonDataForDisplay: any = {
      "Legal Name": isDeleteData.Legal_Name,
      "Brand Name": isDeleteData.Brand_Name,
      "Short Name": isDeleteData.Short_Name,
      "Parent Company Name": isDeleteData?.Parent_Company_Name_Value,
      "Company Type": isDeleteData?.Company_Type_Name,
      "Group Name": isDeleteData.Group_Name,
      Industry: isDeleteData?.Industry_Name_Value,
      Sector: Sector_name,
      Website: isDeleteData?.WebSite,
      CIN: isDeleteData.CIN,
      TAN: isDeleteData.TAN,
      PAN: isDeleteData.PAN,
      Constitution: isDeleteData?.Constitution_Value,
      Jurisdiction: isDeleteData?.Jurisdiction_Value,
      "Line Of Business Mapping": LOB_name,
      "Department Mapping": Department_name,
      Regulator: isDeleteData?.Regulator_Name,
      About: isDeleteData.About_Company,
      Status: isDeleteData.IsActive === "Active" ? "Inactive" : "Active",
      "Modified By": user?.UserName,
      CompanyAddressAsJson: isDeleteData.CompanyAddressAsJson,
      CompanyRepresentativeAsJson: isDeleteData.CompanyRepresentativeAsJson,
    };

    const payload = {
      Brand_Name:formData.Brand_Name,
      Short_Name:formData.Short_Name,
      Is_Parent_Company:formData.Is_Parent_Company,
      Company_Type:formData.Company_Type,
      Parent_Company_Name:formData.Parent_Company_Name,
      Group_Name:formData.Group_Name,
      Unique_Id_Type:formData.Unique_Id_Type,
      Unique_Id:formData.Unique_Id,
      TAN:formData.TAN,
      PAN:formData.PAN,
      CIN:formData.CIN,
      Constitution:formData.Constitution,
      Jurisdiction:formData.Jurisdiction,
      About_Company:formData.About_Company,
      Is_Regulated:formData.Is_Regulated,
      Regulator:formData.Regulator,
      Company_LOB:formData.Company_LOB,
      Department:formData.Department,
      Logo:formData.Logo,
      Industry:formData.Industry,
      WebSite:formData.WebSite,
      Sector:formData.Sector,
      Status:formData.Status,
      CompanyAddressAsJson:formData.CompanyAddressAsJson,
      CompanyRepresentativeAsJson:formData.CompanyRepresentativeAsJson,
      Remarks:formData.Remarks,

      Company_Id: formData.Company_Id,
      Legal_Name: formData.Legal_Name,
      Updated_By: userLoginResponse?.UserName,
      CreatedBy: userLoginResponse?.UserName,
      ModuleName: "Company Master",
      MenuId: permissionPage?.MenuId,
      Form_Id: 19,
      UserId: userLoginResponse?.UserId,
      IsActive: formData.IsActive === "Active" ? false : true,
      Activity: formData.IsActive === "Active" ? "Inactive" : "active",
      DataForDisplay: JSON.stringify(JsonDataForDisplay),
      Updated_On: dayjs(),
    };

    dispatch(deleteCompany(payload));
  };

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  const handleOpen = async (data: any, Option: string) => {
  
    setOption(Option);
    if (Option == "edit" && isDraft) {
      setIsEdit(true);
      setEditId(data?.id);
      setIsEditData(data);
      setinitialData(data);
    } else if (Option == "edit" && !isDraft) {
      setIsEdit(true);
      setEditId(data?.id);
      setIsEditData(data);
      setinitialData(data);
     

      
    } else if (Option === "view") {
      setOption(Option);
      setIsEditData(data);
      setinitialData(data);
      setIsEdit(true);
    } else if (Option === "history") {
      setOption(Option);
      setIsVersionHistory(true);
      dispatch(
        getCompanyHistory({
          Company_Id: data.Company_Id,
          FormId:FormIdConfig.CompanyFormId,
        })
      );

    }
    if (Option === "delete") {
      setOption(Option);
      setIsDeleteData(data);
    
    }

    // setUserId(data.UserId);
  };

  const next = () => {
    handleNext && handleNext();
  };

  const handleCompanyAction = () => {
    dispatch(updateCompanyAddandEdit(false));
    dispatch(
      updateToaster({
        isToaster: true,
        toasterMsg: ToasterMsg.Message,
        isTosterFailed: ToasterMsg.Status === "false" ? true : false,
      })
    );
    dispatch(
      getCompanyList({
        FormID: 2,
      })
    );
    dispatch(updateLoader(false));
    setIsEdit(false);
    setAddForm(false);
  };

  const handleDraftAction = () => {
    dispatch(updateSaveasDraftAddandEdit(false));
    dispatch(updateToaster({ isToaster: true, toasterMsg: DraftToasterMsg }));
    setIsEdit(false);
    setAddForm(false);

    callDraft();
    dispatch(updateLoader(false));
  };

  useEffect(() => {
    if (isCompanyAdded || isCompanyUpdated) {
      handleCompanyAction();
      handleRecordLockUpdate();
      setIsEdit(false);
      setAddForm(false);
    } else if (isCompanyDeleted) {
      setIsDeleteData(null);
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: ToasterMsg.Message,
          isTosterFailed: ToasterMsg.Status === "false" ? true : false,
        })
      );
      setTimeout(() => {
        dispatch(updateCompanyAddandEdit(false));
      }, 1000);
      dispatch(
        getCompanyList({
          FormID: 2,
        })
      );
      dispatch(updateLoader(false));
      setIsEdit(false);
      setAddForm(false);
    }
  }, [isCompanyAdded, isCompanyDeleted, isCompanyUpdated]);

  useEffect(() => {
    if (isDraftAdded || isDraftUpdated || isDraftDeleted) {
      handleDraftAction();
    }
  }, [isDraftAdded, isDraftDeleted, isDraftUpdated]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              // flexDirection: "row",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" color={"text.primary"}>
              {" "}
              {currentpageTitleData ? currentpageTitleData?.ViewTitle : currentPagePermissionData?.MenuName}
              {/* Holiday Master */}
            </Typography>
            {/* {permissionPage && <PageMainHeading title={permissionPage?.MenuName} />} */}

            {currentPagePermissionData?.MenuName === "Customer Onboarding" ? (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Grid
                  sx={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "center",
                  }}
                >
                  <CustomButton
                    variant="contained"
                    name={"Proceed to next master"}
                    onClick={() => next()}
                    size="small"
                  />
                </Grid>
              </Box>
            ) : (
              <></>
            )}
          </Box>

          <Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <MaterialDynamicGrid
                data={columns}
                rows={!isDraft ? dataTable : DraftTableData && DraftTableData.length > 0 ? DraftTableData : []}
                handleAction={handleOpen}
                enableZoomIcon={true}
                // showDelete={permissionPage?.IsDelete === 1 ? true : false}
                handleAdd={() => setAddForm(true)}
                handleIsDraft={() => {
                  setIsDraft(!isDraft);
                  if (!isDraft) {
                    callDraft();
                  }
                  //console.log()
                }}
                draftCount={DraftTableData?.length || 0}
                showAdd={!isDraft ? permissionPage?.IsDelete === 1 : false}
                isEnableDraft={!isDraft ? 1 : 2}
                showhistory={!isDraft ? true : false}
                disableselect={isDraft ? true : false}
                isDraftView={true}
                showDelete={!isDraft ? permissionPage?.IsDelete === 1 : false}
                showView={!isDraft ? permissionPage?.IsView === 1 : false}
                showEdit={!isDraft ? permissionPage?.IsEdit === 1 : permissionPage?.IsEdit === 1}
                showExports={!isDraft ? permissionPage?.IsExport === 1 : permissionPage?.IsExport === 1}
                  isEnableRecordLock = {{ IdField : "Company_Id" , data :  {   moduleName : currentPagePermissionData?.MenuName || "",
                                                  FormID : FormIdConfig?.CompanyFormId,
                                                
                                                  MenuID : currentPagePermissionData?.MenuId,
                                                  IsCheckPending : true  }}}
                pageName="Company Master"
                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              />
            </Box>
          </Box>
        </Stack>
      </Box>

      <CustomDialog
        show={addForm}
        onHide={() => setAddForm(false)}
        maxWidth={"lg"}
        minHeight={"50vh"}
        header={`Add ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Add Company"}`}
        style={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              overflowY: "hidden",
            },
          },
        }}
        contentNode={
          <CompanyMasterStepper
            view={false}
            closePopUp={() => {
              setAddForm(false);
            }}
            setFormDatas={setFormDatas}
            formDatas={formDatas}
            setIsEditData={setIsEditData}
          />
        }
      />

      <CustomDialog
        show={isEdit}
        style={{
          "& .MuiPaper-root MuiPaper-elevation": {
            overflowY: "hidden",
          },
        }}
        onHide={() => {
          setIsEdit(false);
          if (Option === "edit" && !isDraft) {
            handleRecordLockUpdate();
          }
        }}
        isEdit_Backdrop={false}
        maxWidth={"lg"}
        minHeight={"50vh"}
        header={
          Option === "view"
            ? `View ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Company Master"}`
            : `Update ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Company Master"}`
        }
        contentNode={
          <>
            {Option === "edit" && (
              <CompanyMasterStepper
                view={false}
                closePopUp={() => {
                  handleRecordLockUpdate();
                  setIsEdit(false);
                  setFormDatas([]);
                }}
                edit={isEdit}
                isEditData={IsEditData}
                setFormDatas={setFormDatas}
                formDatas={formDatas}
                setIsEditData={setIsEditData}
                initialData={initialData}
                setinitialData={setinitialData}
              />
            )}
            {Option === "view" && (
              <CompanyMasterStepper
                view={true}
                closePopUp={() => {
                  setIsEdit(false);
                  setFormDatas([]);
                }}
                edit={isEdit}
                isEditData={IsEditData}
                setFormDatas={setFormDatas}
                formDatas={formDatas}
                setIsEditData={setIsEditData}
                initialData={initialData}
                setinitialData={setinitialData}
              />
            )}
          </>
        }
      />

      {versionHistory?.CompanyRecords && (
        <CustomDialog
          show={isVersionHistory}
          onHide={() => {
            setIsVersionHistory(false);
          }}
          maxWidth={"lg"}
          // header={"Company Master Version History"}
          header={`${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Company"} Version History`}
          contentNode={
            <>
              {" "}
              <MaterialDynamicGrid
                data={versionHistoryData}
                rows={versionHistory.CompanyRecords}
                handleAction={handleOpen}
                enableZoomIcon={false}
                showDelete={false}
                showhistory={false}
                showView={false}
                showEdit={false}
                showExports={true}
                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                pageName="Company Master Version History"
              />
            </>
          }
        />
      )}
      <ModalPopUp
        onDeleteMethod={() => deleteCompanyMaster()}
        isDelete={true}
        onHide={() => setIsDeleteData(null)}
        show={isDeleteData ? true : false}
        header={"Confirm"}
        text={`Are you sure you want to ${isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"
          } the selected Company Master`}
        ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}
      />
    </>
  );
};
