import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, IconButton } from "@mui/material";
import { PermissionColumn } from "../../utils/constants";
import { RootState, useAppDispatchThunk } from "../../store/store";
import { useSelector } from "react-redux";
import RemarkSectionApproval from "./RemarkSection";
import CustomSvg from "../../common/CustomSvg";
import { useTheme } from "@mui/material/styles";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import MaterialDynamicRolesGrid from "../../common/dynamicDataGrid/MaterialDynamicRolesGrid";

interface basicTableProps {
  selectedGridRecord: any;
  selectedRecordDetails: any;
  setSelectedRecordDetails?: any;
  closePopUp: any;
  view?: boolean;
  edit?: boolean;
}

const UserMasterApproveRejectPendingRecord: React.FC<basicTableProps> = ({
  selectedGridRecord,
  selectedRecordDetails,
  setSelectedRecordDetails,
  closePopUp,
  view,
  edit,
}) => {
  const [oldValues, setOldValues] = useState<any>("");
  const [oldValuesMenu, setOldValuesMenu] = useState<any>([]);
  const [newValues, setNewValues] = useState<any>("");
  const [newValuesMenu, setNewValuesMenu] = useState<any>([]);
  const [header, setHeader] = useState<any>([]);
  const theme = useTheme();
  const [openUserMenu, setOpenUserMenu] = useState<boolean>(false);
  const [menuCategory, setMenuCategory] = useState<any>(null);

  const dispatch = useAppDispatchThunk();
  const { makerCheckerStatus, isApprovedorReject, isApprovedorRejectFailed } = useSelector(
    (state: RootState) => state.pendingApproval
  );
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    if (isApprovedorReject) {
      setLoader(false);
      closePopUp && closePopUp();
    } else if (isApprovedorRejectFailed) {
      setLoader(false);
    }
  }, [isApprovedorReject, isApprovedorRejectFailed]);

  const UserDisplayColumn = [
    {
      Field: "First Name",
      FieldType: "string",
      DisplayName: "First Name",
    },
    {
      Field: "Last Name",
      FieldType: "string",
      DisplayName: "Last Name",
    },
    {
      Field: "Email Id",
      FieldType: "string",
      DisplayName: "Email Id",
    },
    {
      Field: "Mobile",
      FieldType: "string",
      DisplayName: "Mobile",
    },
    {
      Field: "Reporting To",
      FieldType: "string",
      DisplayName: "Reporting To",
    },
    {
      Field: "Country",
      FieldType: "string",
      DisplayName: "Country",
    },
    {
      Field: "Office Address",
      FieldType: "string",
      DisplayName: "Office Address",
    },
    {
      Field: "Role",
      FieldType: "string",
      DisplayName: "Role",
    },
    {
      Field: "Department",
      FieldType: "string",
      DisplayName: "Department",
    },
    {
      Field: "Status",
      FieldType: "string",
      DisplayName: "Status",
    },
    {
      Field: "DesignationId",
      FieldType: "string",
      DisplayName: "Designation Id",
    },
    {
      Field: "BranchId",
      FieldType: "string",
      DisplayName: "Branch Id",
    },
    {
      Field: "Modified By",
      FieldType: "string",
      DisplayName: "Modified By",
    },
    {
      Field: "userMenus",
      FieldType: "image",
      DisplayName: "User Menu",
    }
  ];

  useEffect(() => {
    if (selectedRecordDetails) {
      if (selectedRecordDetails?.NewValues) {
        const newHeader: any = [{ DisplayName: "Fields" }]
        if (selectedRecordDetails && Object.keys(selectedRecordDetails?.OldValues).length > 0) {
          newHeader.push({ DisplayName: "Old Values" });
        }
        newHeader.push({ DisplayName: "New Values" });
        setHeader(newHeader);
      }
      if (selectedRecordDetails && Object.keys(selectedRecordDetails?.OldValues).length > 0) {
        setOldValues(selectedRecordDetails?.OldValues);
        const userMenu = selectedRecordDetails?.OldValues?.userMenus
        setOldValuesMenu(userMenu ? userMenu : []);
      }
      if (selectedRecordDetails?.NewValues) {
        setNewValues(selectedRecordDetails?.NewValues);
        const userMenu = selectedRecordDetails?.NewValues?.userMenus
        setNewValuesMenu(userMenu ? userMenu : []);
      }
    }
  }, [selectedRecordDetails]);
  
  return (
    <>
      <Stack spacing={3}>
        <Box>
          <Typography variant="h6" sx={{ margin: "16px 0", fontWeight: "bold" }}>
            User Details
          </Typography>
          <Table sx={{ width: "100%", marginBottom: "24px" }}>
            <TableHead>
              <TableRow>
                {header.map((col: any) => (
                  <TableCell
                    key={`${col.DisplayName}-column Section company Master approval`}
                    sx={{
                      textAlign: "center",
                      fontFamily: "aptos-bold",
                      border: "2px solid #f1f1f1",
                    }}
                  >
                    {col.DisplayName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {UserDisplayColumn.map((col: any) => (
                <TableRow>
                  <TableCell
                    key={`${col.DisplayName}-column Section company Master approval`}
                    sx={{
                      textAlign: "left",
                      border: "2px solid #f1f1f1",
                    }}
                  >
                    {col.DisplayName}
                  </TableCell>
                  {oldValues ? (
                    <TableCell
                      key={`${col.DisplayName}-column Section company Master approval`}
                      sx={{
                        textAlign: "left",
                        border: "2px solid #f1f1f1",
                      }}
                    >
                      {col.FieldType !== "image" ? oldValues[col.Field] : oldValues[col.Field] && (
                        <Tooltip title="View">
                          <IconButton
                            onClick={() => {
                              setMenuCategory('old')
                              setOpenUserMenu(true)
                            }}
                          >
                            <CustomSvg color={`${theme.palette.info.main}`} size={"20px"} name={"view"} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  ) : null}
                  {newValues ? (
                    <TableCell
                      key={`${col.DisplayName}-column Section company Master approval`}
                      sx={{
                        textAlign: "left",
                        border: "2px solid #f1f1f1",
                      }}
                    >
                      {col.FieldType !== "image" ? newValues[col.Field] : newValues[col.Field] && (
                        <Tooltip title="View">
                          <IconButton
                            onClick={() => {
                              setMenuCategory('new')
                              setOpenUserMenu(true)
                            }}
                          >
                            <CustomSvg color={`${theme.palette.info.main}`} size={"20px"} name={"view"} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  ) : null}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Box>
          <RemarkSectionApproval selectedGridRecord={selectedGridRecord} closePopUp={closePopUp} view={view} />
        </Box>
      </Stack>

      <CustomDialog
        show={openUserMenu}
        onHide={() => {setOpenUserMenu(false);setMenuCategory('')}}
        maxWidth={"md"}
        header={"User Menu"}
        contentNode={
          <MaterialDynamicRolesGrid data={PermissionColumn} rows={menuCategory === 'old'? oldValuesMenu: newValuesMenu} handleAction={()=>{}} view={true}/>
        } />
    </>
  );
};

export default UserMasterApproveRejectPendingRecord;
