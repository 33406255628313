import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useState, createContext, useEffect } from "react";
import CustomBreadcrumbs from "../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { extractContent, getDateOrConvertSameTime, textFieldWidth } from "../../../utils/constants";
import LableandValuesPreview from "../../../common/CommonPreview/LableandValuesPreview";
import { Requirement } from "./requirement/Requirement";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import { TargetKPI } from "./targetKPI/TargetKPI";
import { AddTargetKPI } from "./targetKPI/addTargetKPI/AddTargetKPI";
import CustomStepper from "../../../common/dynamicSteper/CustomStepper";
import { ContractDetails } from "../PMCommonDetails/ContractDetails";
import { getClauseDetailsList } from "../../../store/contractManagement/slices/clauseDataSlice";
import { RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import { getLovList } from "../../../store/slices/lovMasterData";
import { getWorkList } from "../../../store/slices/workFlowSlice";
import { getUsersList } from "../../../store/slices/userData";
import { getCurrencyList } from "../../../store/slices/currencyData";
import { getRiskRateList } from "../../../store/slices/riskRateMasterData";
import { ApproveRejectScope } from "../ScopeApprovel/scopeApprovalPreview/ApproveRejectScope";
import CustomSvg from "../../../common/CustomSvg";
import { RequirementDetails } from "../ScopeApprovel/scopeApprovalPreview/RequirementDetails";
import { LovConfig, PM_MeasuredIn, PR_Scope_Status, ToasterActivityConfig } from "../../../config/config";
import { addUpdateScope, PMScopeAddandEditUpdate } from "../../../store/performanceManagement/slices/defineScope";
import { getMetricsMasterList } from "../../../store/slices/metricsMasters";
import { findMeasuredInDate } from "./targetKPI/addTargetKPI/addSLAValidation";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import { addSaveandDraft, deleteSaveandDraft, updateSaveandDraft } from "../../../store/slices/saveasdraft";
import { getClauseDetailsBycontract } from "../../../store/contractManagement/slices/PMContractListSlice";
import { getAllSeverityMaster } from "../../../store/performanceManagement/slices/severityMaster";

interface ScopeDefinitionprops {
  view?: boolean;
  closePopUp?: any;
  edit?: boolean;
  isEditData?: any;
  isDraftData?: any;
  add?: boolean;
  contarctDetails?: any;
  prePageName?: string;
}

interface requirmentContextProps {
  selectedRequirement: any;
  requirementList: any[];
  validationErrors?: any;
  selectedRequirementIndex?: any;
  addTarget?: any;
  validateRequirement: () => void;
  setRequirementList: (value: any) => void;
  setaddTarget: (value: any) => void;
  isnorequirementwarning?: boolean;
  isnotargetwarning?: boolean;
}

export const RequirementDetailContext = createContext<requirmentContextProps | undefined>(undefined);
export const ScopeDefinition: React.FC<ScopeDefinitionprops> = ({isDraftData , add , view, closePopUp, edit, isEditData , contarctDetails , prePageName }) => {
  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
      const performanceManagementReducer: any = useSelector((state: RootState) => state?.performanceManagementReducer);
  
  const {isScopeAdded , isScopeLoading , ToasterMsg } = useSelector(
    (state: RootState) => performanceManagementReducer.PMScopeDataSlice
  );
      const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
       const { contractClauseDetails } = useSelector(
                  (state: any) => state.contractManagementReducer?.PMContractListSlice
              );
  
  const dispatch = useAppDispatch();
  const [ScopeTitle, setScopeTitle] = useState("PR Scope Definition");
  const [measuredList, setmeasuredList] = useState<any>([]);
  const [scopeStatus, setscopeStatus] = useState<any>([]);
  const [steps, setsteps] = useState<any>(0);
   //----------Requirement----
  const [requirementList, setRequirementList] = useState<any>([]);
  const [selectedRequirementIndex, setselectedRequirementIndex] = useState<any>("");
  const [selectedRequirement, setselectedRequirement] = useState<any>("");
  //----------preview----
  const [requirementPreviewDetails, setrequirementPreviewDetails] = useState<any[]>([]);
  const [selectedRequirementPreview, setselectedRequirementPreview] = useState<any>("");
  const [selectedRequirementPreviewIndex, setselectedRequirementPreviewIndex] = useState<any>("");

  // ---------------
  const [validationErrors, setvalidationErrors] = useState<any>({});
  const [addTarget, setaddTarget] = useState<any>(false);
  const [isnorequirementwarning, setisnorequirementwarning] = useState<any>(false);
  const [isnotargetwarning, setisnotargetwarning] = useState<any>(false);
  const [contractdetails, setcontractdetails] = useState<any>({});
  const [iscloseWarning, setiscloseWarning] = useState<any>(false);

  const closeFormValue = (val : any) => {
    if ( val === 0) {
      
      closewarningPopup()
    }
  };

  useEffect(() => {
    dispatch(updateLoader(isScopeLoading ));
  },[isScopeLoading])

   useEffect(() => {
      if (isScopeAdded) {
        closePopUp &&  closePopUp()
        if(isDraftData){
          deleteDraftList()
        }
        dispatch(PMScopeAddandEditUpdate(false));
        dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg?.Message || "" }));
       
      }
    }, [isScopeAdded]);

      const deleteDraftList = () => {
        let formData = {
          DraftId: isDraftData?.DraftId,
          CreatedBy: user?.UserName,
        };
    
        dispatch(deleteSaveandDraft(formData));
      };


  useEffect(() => {
    initializeData();
  }, []);

  useEffect(() => {
    if(contarctDetails && contarctDetails?.vendor_Contract_Id){
      dispatch(getClauseDetailsBycontract({ id :  contarctDetails?.vendor_Contract_Id  }));
    }
   
    
  },[contarctDetails])



  const parseSeverityOfTarget = (data: any[]) => {
    return data.map((pmprClause) => {
      const parsedTargets = pmprClause.pmprClauseTarget.map((target: any) => {
        const parsedSeverity = target.pmprctScope?.severityof_Target
          ? JSON.parse(target.pmprctScope.severityof_Target)
          : [];
          const modifiedsme_Involved = target.pmprctScope?.sme_Involved ? JSON.parse(target.pmprctScope.sme_Involved)
          : []

          const modifiedSla = (target.pmprctsla || []).map((slaItem: any) => {
            const isDate = slaItem?.measured_In_Name === PM_MeasuredIn.Date;
    
            return {
              ...slaItem,
              target_Min: !isDate ? slaItem?.target_Min : "",
              target_Max: !isDate ? slaItem?.target_Max : "",
              target_min_date: isDate ? slaItem?.target_Min : null,
              target_max_date: isDate ? slaItem?.target_Max : null,
            };
          });
  
        return {
          ...target,
       
          pmprctsla : modifiedSla ,
          pmprctScope: {
            ...target.pmprctScope,
            sme_Involved : modifiedsme_Involved ,
            severityof_Target: parsedSeverity,
          },
        };
      });
  
      return {
        ...pmprClause,
        clause_content : pmprClause?.clause_content ? extractContent(pmprClause?.clause_content) : "" ,
        pmprClauseTarget: parsedTargets,
      };
    });
  };

 
   useEffect(() => {

    if(isEditData && isEditData?.pmprClauses){
      let data : any = parseSeverityOfTarget(isEditData?.pmprClauses);
      console.log(data , "Edit Use Data");
      
      setRequirementList(data)
    }
   },[isEditData , edit , view ])


   useEffect(() =>{

    if(isDraftData && isDraftData?.formdata && isDraftData?.formdata?.pmprClauses){

      let data : any = parseSeverityOfTarget(isDraftData?.formdata?.pmprClauses);
      setRequirementList(data)
    }
    
      
   },[isDraftData])

     useEffect(() => {
      console.log(contractClauseDetails , "contractClauseDetails");
      
       if(contractClauseDetails && add && !isDraftData){
         let SLAClausesList : any = [];
         contractClauseDetails.forEach((item : any) => {
          if(item?.sla){
           let obj = {
             pR_Clause_Id :  0 ,
             prRef_Id :  0 ,
             clause_Id:  item?.clauseId,
             clause_Header:  item?.clasue_Header,
             clause_reference_no:  "1.0",
             clause_content: extractContent(item?.clasue_Body),
             exclude:  false,
             reason_For_Exclude:  "",
             isActive:  "",
           }
          
           
           SLAClausesList.push(obj)
          }
         })
         console.log(SLAClausesList , "SLAClausesList");
         setRequirementList(SLAClausesList)   
       }
   
     },[contractClauseDetails , isDraftData , add])
  useEffect(() => {
   if(contarctDetails){
    setcontractdetails(contarctDetails)
   }
  },[contarctDetails])

  const initializeData = () => {
    dispatch(getClauseDetailsList());
    dispatch(getLovList({ Name: "" }));
    dispatch(getWorkList());
    dispatch(getMetricsMasterList({}));
    dispatch(
      getUsersList({
        FormID: 2,
      })
    );
    dispatch(getCurrencyList({ FormID: 0 }));
    dispatch(
      getRiskRateList({
        FormID: 2,
      })
    );
      dispatch(getAllSeverityMaster({
                    FormID: 2
                }))
  };
  useEffect(() => {
    setmeasuredList(lovTable.filter((item: any) => item.Name === LovConfig?.Measured_In) || []);
    setscopeStatus(lovTable.filter((item: any) => item.Name === LovConfig?.PR_scope_Status) || []);
  }, [lovTable]);
  // target page next and previous button function
  const setNextScope = (isAdd: boolean) => {
    const requirementListValue = [...requirementList];
 if(steps == 1){
    if (isAdd) {
      // Handle the "add" case
      if (requirementListValue.length > selectedRequirementIndex + 1) {
        setselectedRequirement(requirementListValue[selectedRequirementIndex + 1]);
        setselectedRequirementIndex((prev: any) => prev + 1);
      }
    } else {
      // Handle the "negative" case (decrement)
      if (selectedRequirementIndex > 0) {
        setselectedRequirement(requirementListValue[selectedRequirementIndex - 1]);
        setselectedRequirementIndex((prev: any) => prev - 1);
      }
    }
  }else{
    if (isAdd) {
      // Handle the "add" case
      if (requirementPreviewDetails.length > selectedRequirementPreviewIndex + 1) {
        setselectedRequirementPreview(requirementPreviewDetails[selectedRequirementPreviewIndex + 1]);
        setselectedRequirementPreviewIndex((prev: any) => prev + 1);
      }
    } else {
      // Handle the "negative" case (decrement)
      if (selectedRequirementPreviewIndex > 0) {
        setselectedRequirementPreview(requirementPreviewDetails[selectedRequirementPreviewIndex - 1]);
        setselectedRequirementPreviewIndex((prev: any) => prev - 1);
      }
    }
        
  }
  };
  //final submit function
  const handleScopeSubmit = async () => {
    let valid: boolean = listtargetkpivalidation();
    if (valid) {
      let formData : any = await createFinalData(true)
      setrequirementPreviewDetails(formData?.pmprClauses || [])
      setselectedRequirementPreview(formData?.pmprClauses[0] || [])
      setselectedRequirementPreviewIndex(0)
      setisnotargetwarning(false);
      setsteps(2);

    } else {
      setisnotargetwarning(true);
    }
    console.log(requirementList, "requirementList", valid);
  };

  const  createFinalData = async (extraFields : boolean) => {
           // crate Main Api Details
      let formdata: any = {
        "prRef_Id": edit ? isEditData?.prRef_Id : 0,
        "prRef_Status": scopeStatus.find((prStatus : any) => prStatus?.Options == PR_Scope_Status.Approval_Pending)?.LovId,
        "vendor_Contract_Id": edit ? isEditData?.vendor_Contract_Id :  contarctDetails?.vendor_Contract_Id ,
        "prRef_Number": edit ? isEditData?.prRef_Number : "",
        "isActive": edit ? isEditData?.isActive : true, 
        "updatedBy": userLoginResponse?.UserName,
        "updatedOn": new Date(),
        "moduleName": "Define Scope",
        "activity": edit ? ToasterActivityConfig?.Edit : ToasterActivityConfig?.create,
        "pmprClauses": [],
      };
       // crate Requirement Data
      let Formated_pmprClauses: any[] = [];
      requirementList?.forEach((item: any , clasuseIndex : number) => {
        let requirementValues: any = {
          ...(extraFields ? { ...item } : {}),
          "index_No" : clasuseIndex + 1 ,
          "pR_Clause_Id": item?.pR_Clause_Id,
          "prRef_Id": item?.prRef_Id,
          "clause_Id": item?.clause_Id,
          "exclude": item?.exclude,
          "reason_For_Exclude": item?.exclude ? item?.reason_For_Exclude : "",
          "isActive": true,
          "updatedBy": userLoginResponse?.UserName,
          "updatedOn": new Date(),
          "pmprClauseTarget": [],
        };
         let  Formated_pmprClauseTarget : any[] = []
        item?.pmprClauseTarget?.forEach((clause_item : any , targetIndex : number) => {
         let  pmprctScopeValuesbefore =  clause_item?.pmprctScope 
         let  pmprctMonitorPeriodsValuesbefore =  clause_item?.pmprctMonitorPeriods 
         let  pmprctslaValuesbefore =  clause_item?.pmprctsla 
              // create Scope Data
             let pmprctScopeValues : any = {  
              ...(extraFields ? { ...pmprctScopeValuesbefore } : {}),
              
              "pR_Clause_Target_Scope_Id": pmprctScopeValuesbefore?.pR_Clause_Target_Scope_Id  ,
              "pR_Clause_Target_Id": clause_item?.pR_Clause_Target_Id,
              "scope_Of_Target": pmprctScopeValuesbefore?.scope_Of_Target,
              "category": pmprctScopeValuesbefore?.category,
              "deliverables": pmprctScopeValuesbefore?.deliverables ,
              "obligation":  pmprctScopeValuesbefore?.obligation ,
              "exclusion": pmprctScopeValuesbefore?.exclusion ,
              "sme_Involved":  JSON.stringify(pmprctScopeValuesbefore?.sme_Involved),
              "input_Dependency": pmprctScopeValuesbefore?.input_Dependency,
              "evidence_Required": pmprctScopeValuesbefore?.evidence_Required || false,
              "project_Cost": pmprctScopeValuesbefore?.project_Cost,
              "currency":  pmprctScopeValuesbefore?.currency,
              "penality_For_Deviation": pmprctScopeValuesbefore?.penality_For_Deviation || false,
              "stakeholder_acceptance_required": pmprctScopeValuesbefore?.penality_For_Deviation || false, 
              "penality_deviation_remark": pmprctScopeValuesbefore?.penality_For_Deviation ? pmprctScopeValuesbefore?.penality_deviation_remark : "",
              "severityof_Target": JSON.stringify(pmprctScopeValuesbefore?.severityof_Target),
              "isActive": true,
              "updatedBy": userLoginResponse?.UserName,
              "updatedOn": new Date(),
             }
            // create Monitoring Review Dates 
             let pmprctMonitoringPeriodReviewDatesbefore = pmprctMonitorPeriodsValuesbefore?.reviewDates || []
             let pmprMonitorDatesValues : any[] = []
             pmprctMonitoringPeriodReviewDatesbefore?.forEach((dateItem : any , reviewDatesIndex : number) => {
             let obj : any =  {
              ...(extraFields ? { ...dateItem } : {}),
                "index_No" : reviewDatesIndex + 1 ,
                "pR_Clause_Review_ID": dateItem?.pR_Clause_Review_ID || 0,
                "pR_Clause_Target_Monitor_Id": pmprctMonitorPeriodsValuesbefore?.pR_Clause_Target_Monitor_Id ||  0,
                "review_StartDate": dateItem?.review_StartDate ,
                "review_EndDate": dateItem?.review_EndDate,
                "notify_Date": dateItem?.notify_Date,
                "escalation_Date": dateItem?.escalation_Date,
                "isActive": true,
                "updatedBy": userLoginResponse?.UserName,
                "updatedOn": new Date(),
            }
            pmprMonitorDatesValues.push(obj)
             })
            // create Monitoring Data
            let pmprctMonitorPeriodsValues : any  = {
              ...(extraFields ? { ...pmprctMonitorPeriodsValuesbefore } : {}),
              "pR_Clause_Target_Monitor_Id": pmprctMonitorPeriodsValuesbefore?.pR_Clause_Target_Monitor_Id || 0,
              "pR_Clause_Target_Id": clause_item?.pR_Clause_Target_Id ,
              "start_Date": getDateOrConvertSameTime(pmprctMonitorPeriodsValuesbefore?.start_Date?.$d, "start"),
              "end_Date": getDateOrConvertSameTime(pmprctMonitorPeriodsValuesbefore?.end_Date?.$d, "end")  ,
              "review_Type": pmprctMonitorPeriodsValuesbefore?.review_Type,
              "frequency": pmprctMonitorPeriodsValuesbefore?.frequency || 0,
              "review_Trigger": pmprctMonitorPeriodsValuesbefore?.review_Trigger,
              "review_Start_Date": getDateOrConvertSameTime(pmprctMonitorPeriodsValuesbefore?.review_Start_Date?.$d, "start"),
              "review_End_Date": getDateOrConvertSameTime(pmprctMonitorPeriodsValuesbefore?.review_End_Date?.$d, "end"),
              "reminder_Before": pmprctMonitorPeriodsValuesbefore?.reminder_Before,
              "escalation_Before": pmprctMonitorPeriodsValuesbefore?.escalation_Before,
              "reviewDates": pmprMonitorDatesValues,
              "isActive": true,
              "updatedBy": userLoginResponse?.UserName,
              "updatedOn": new Date(),
            }

            let pmprctslaValues : any[] = []
             // create SLA List Data
            pmprctslaValuesbefore?.forEach((sla_item : any , slaIndex : number) => {
                 let sla_obj : any = {
                  ...(extraFields ? { ...sla_item } : {}),
                  "index_No" : slaIndex + 1 ,
                  "pR_Clause_SLA_Id": sla_item?.pR_Clause_SLA_Id,
                  "pR_Clause_Target_Id": clause_item?.pR_Clause_Target_Id,
                  "sla": sla_item?.sla,
                  "measured_In":  sla_item?.measured_In,
                  "target_Min": findMeasuredInDate( sla_item?.measured_In , measuredList ) ? sla_item?.target_min_date  : sla_item?.target_Min   ,
                  "target_Max": findMeasuredInDate( sla_item?.measured_In , measuredList ) ? sla_item?.target_max_date  : sla_item?.target_Max  ,
                  "tolerance_Level": sla_item?.tolerance_Level,
                  "bestScore": sla_item?.bestScore,
                  "severity_Of_SLA": sla_item?.severity_Of_SLA,
                  "remark": sla_item?.remark,
                  "isActive": true,
                  "updatedBy": userLoginResponse?.UserName,
                  "updatedOn": new Date(),
                }   
                pmprctslaValues.push(sla_obj)
            })

           // create Target Data
          let targetValues: any = {
            "index_No" : targetIndex + 1 ,
            "pR_Clause_Target_Id": clause_item?.pR_Clause_Target_Id || 0,
            "pR_Clause_Id": item?.pR_Clause_Id ,
            "isActive": true,
            "updatedBy": userLoginResponse?.UserName,
            "updatedOn": new Date(),
             "pmprctScope" : pmprctScopeValues,
             "pmprctMonitorPeriods" : pmprctMonitorPeriodsValues,
             "pmprctsla" : pmprctslaValues
          };
          //push the clasuse target datas
          Formated_pmprClauseTarget.push(targetValues)
        })
        //add the clasuse targets list in clause datas
        requirementValues.pmprClauseTarget = Formated_pmprClauseTarget ;
        //push the clasuse datas
        Formated_pmprClauses.push(requirementValues);
      });
      //add the clasuse list in form data datas
      formdata.pmprClauses = Formated_pmprClauses;
      console.log(formdata, "requirementList valid formdata" , Formated_pmprClauses);

      return formdata;
}

  const handleScopeApproveSubmit = async () => {
    let valid: boolean = listtargetkpivalidation();
    if (valid) {
      setisnotargetwarning(false);
       let formdata : any = await createFinalData(false)
      dispatch(
        addUpdateScope(formdata)
      );
    }
  };


  const handleScopeApproveSubmitDraft = async () => {

      setisnotargetwarning(false);
       let formdata : any = await createFinalData(true)
  
       let finalFormdataandContractData = {...contarctDetails , formdata : formdata }
         let finalDeaftFormData : any = {
          "UserId": userLoginResponse?.UserId,
          "ModuleName": "Define Scope",
          "ScreenName": "Define Scope" ,
          "DraftData": JSON.stringify(finalFormdataandContractData) ,
          "DraftStatus": true,
          "CreatedBy": userLoginResponse?.UserEmail,
          "Activity": ToasterActivityConfig.createDraft
        }

          if (isDraftData) {
            finalDeaftFormData.DraftId = isDraftData.DraftId;
            finalDeaftFormData.UpdatedBy = user?.UserName;
              dispatch(updateSaveandDraft(finalDeaftFormData));
            } else {
              dispatch(addSaveandDraft(finalDeaftFormData));
            }
    
    
  };

  const listtargetkpivalidation = (): boolean => {
    if (requirementList && requirementList.length > 0) {
      for (const item of requirementList) {
        if (!item?.exclude && (!item || !item?.pmprClauseTarget || item.pmprClauseTarget.length === 0)) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  };

  const validateRequirement = () => {
    let errors: { [key: string]: string } = { ...validationErrors };
    requirementList.forEach((item: any, index: any) => {
      if (item && item?.exclude == true && !item?.reason_For_Exclude) {
        errors[`${index}-reason_For_Exclude`] = "Reason For Exclution is required";
      } else {
        delete errors[`${index}-reason_For_Exclude`];
      }
    });
    setvalidationErrors(errors);
    return errors
   
  }

  // check requirment validation and move target kpi button function
  const checkRequirementValidation = () => {
   

    if (requirementList && requirementList?.length > 0) {
      setisnorequirementwarning(false);
      let errors : any =  validateRequirement()
   
      if (Object.keys(errors).length === 0) {
        setsteps(1);
        setselectedRequirementIndex(0);
        setselectedRequirement(requirementList[0]);
      }
    } else {
      setisnorequirementwarning(true);
    }
  };

  // add target form submit function from add target kpi => add target
  const TargetAdd = (val: any) => {
    setRequirementList((prevList: any[]) => {
      const updatedList = [...prevList];
      let updatedListtarget: any[] = [...(updatedList[selectedRequirementIndex]?.pmprClauseTarget || [])];
      updatedListtarget.push(val);
      if (updatedList[selectedRequirementIndex]) {
        updatedList[selectedRequirementIndex] = {
          ...updatedList[selectedRequirementIndex],
          pmprClauseTarget : updatedListtarget,
        };
      }
      setselectedRequirement(updatedList[selectedRequirementIndex]);
      return updatedList;
    });
    setaddTarget(false);
  };

  // close add target
  const closeAddtarget = (val: any) => {
    if (val == 1) {
      setaddTarget(false);
    } else if (val == 0) {
      closewarningPopup()
    }
  };

  const closewarningPopup = () => {
    setiscloseWarning(true)
  }

  return (
    <>
      {!addTarget ? (
        <Box>
          <Stack spacing={4}>
            {prePageName && <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <CustomBreadcrumbs
                list={[
                  { value: 0, name: `${prePageName}` },
                  { value: 1, name: `PR Scope Definition` },
                ]}
                onchange={closeFormValue}
              />
            </Box> }
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6" color={"text.primary"}>
                {ScopeTitle}
              </Typography>
              {add ? <CustomButton disabled={requirementList?.length == 0} type="reset" name={"Save as Draft"} onClick={() => handleScopeApproveSubmitDraft()} /> : null }
             
            </Box>

            <ContractDetails data={contractdetails} />
            <Divider></Divider>
            <Box sx={{ display: "flex", justifyContent: "start", width: "100%", alignItems: "center" }}>
              <Box sx={{ width: "60%" }}>
                <CustomStepper activeStep={steps} steps={["Requirement", "Target/KPI", "Review"]} />
              </Box>
            </Box>
            <RequirementDetailContext.Provider
              value={{
                requirementList,
                setRequirementList,
                validateRequirement,
                selectedRequirement,
                selectedRequirementIndex,
                validationErrors,
                setaddTarget,
                addTarget,
                isnorequirementwarning,
                isnotargetwarning,
              }}
            >
              {steps == 0 && <Requirement add={add} view={view} />}

              {steps == 1 && (
                <>
                  <TargetKPI view={view} add={true}/>
                </>
              )}
              {steps == 2 && (
                <>
                  {" "}
                  <RequirementDetails data={selectedRequirementPreview} currentIndex={selectedRequirementPreviewIndex} RequirementDataLength={requirementPreviewDetails?.length} />{" "}
                </>
              )}
            </RequirementDetailContext.Provider>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", gap: 2 }}>
                <CustomButton type="reset" variant="outlined" name={"Discard"} onClick={() => {}} />
                {(steps == 1 || steps == 2) && (
                  <>
                    {(steps == 1 ? selectedRequirementIndex !== 0 : selectedRequirementPreviewIndex != 0) && (
                      <CustomButton
                        type="reset"
                        variant="outlined"
                        name={"Previous"}
                        onClick={() => setNextScope(false)}
                        startIcon={<CustomSvg size={"15px"} name={"arrow-left"} />}
                      />
                    )}
                     </>
                )}
              </Box>

              <Box sx={{ display: "flex", gap: 2 }}>
                {steps == 0 && (
                  <CustomButton
                    type="reset"
                    name={`${view ? "View" : "Add"} Target/KPI`}
                    variant="outlined"
                    onClick={() => {
                      checkRequirementValidation();
                    }}
                    endIcon={<CustomSvg size={"15px"} name={"arrow-right"} />}
                  />
                )}

                {(steps == 1 || steps == 2) && (
                  <>
                 
                    { (steps == 1 ? selectedRequirementIndex + 1 != requirementList?.length : selectedRequirementPreviewIndex + 1 != requirementPreviewDetails?.length) && (
                      <CustomButton
                        type="reset"
                        variant="outlined"
                        name={"Next"}
                        onClick={() => setNextScope(true)}
                        endIcon={<CustomSvg size={"15px"} name={"arrow-right"} />}
                      />
                    )}
                  </>
                )}

                {steps == 1 && (
                  <>
                    <CustomButton
                      type="reset"
                      variant="outlined"
                      name={"Requirement"}
                      onClick={() => setsteps(0)}
                      startIcon={<CustomSvg size={"15px"} name={"arrow-left"} />}
                    />
                    <CustomButton
                      type="reset"
                      variant="outlined"
                      name={"Review"}
                      onClick={() => handleScopeSubmit()}
                      endIcon={<CustomSvg size={"15px"} name={"arrow-right"} />}
                    />
                  </>
                )}

                {steps == 2 && (
                  <>
                    {" "}
                    <CustomButton
                      type="reset"
                      name={"Back to Add Target/KPI"}
                      variant="outlined"
                      startIcon={<CustomSvg size={"15px"} name={"arrow-left"} />}
                      onClick={() => {
                        setsteps(1);
                      }}
                    />{" "}
                   {!view ? <CustomButton type="reset" name={"Submit"} onClick={() => handleScopeApproveSubmit()} /> : null }
                  </>
                )}
              </Box>
            </Box>
          </Stack>
        </Box>
      ) : (
        <AddTargetKPI view={view} closeAddtarget={(val) => closeAddtarget(val)} add={true} handlesubmit={(val) => TargetAdd(val)} />
      )}

<ModalPopUp
            onDeleteMethod={() => closePopUp && closePopUp()}
            isDelete={true}
            onHide={() => {
              setiscloseWarning(false)
            }
            }
            ActionName={"Close"}
            show={iscloseWarning}
            header={"Confirm"}
            text={`Are you sure you want to Close?`}
          />
    </>
  );
};
