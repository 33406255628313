import React from "react";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import CustomButton from "../dynamicButton/CustomButton";
import CustomSvg from "../CustomSvg";

import { useTheme } from "@mui/material/styles";

interface  headerProps {
   
        Field: string ;
        FieldType?: string ;
        DisplayName: string ;
        config?:any;
        options?: string[],
        optionsField?: string,
    
}
interface ExportBulkUploadProps  {
    header : headerProps[];
    sheetName? : string ;
    Data?: any [] ;
    Note?: any;
    buttonName? : string
}

 const CommonDataTemplateSheet = (props : ExportBulkUploadProps) => {
       
    const theme = useTheme();
    const {
        header = [] ,
        sheetName = 'Clife',
        Data = [{}],
        Note,
        buttonName = "Download Template",
    } = props;

    const generateExcel = async (templateHeader: any[], rowData: any[]) => {
      const workbook = new ExcelJS.Workbook();
      
      // Create the main worksheet
      const mainSheet = workbook.addWorksheet("MainSheet");
    
      // Create the hidden worksheet
      const hiddenSheet = workbook.addWorksheet("HiddenSheet");
    
      // Map for dropdown references
      const dropdownReferences: Record<string, string> = {};
    
      // Populate the hidden sheet with dropdown options dynamically
      templateHeader.forEach((header, headerIndex) => {
        if (header.options || header.optionsField) {
          rowData.forEach((row, rowIndex) => {
            const options = header.options || row[header.optionsField];
            if (options && Array.isArray(options)) {
              const col = headerIndex + 1;
              const startRow = hiddenSheet.rowCount + 1;
    
              options.forEach((option, optIndex) => {
                hiddenSheet.getCell(startRow + optIndex, col).value = option;
              });
    
              const endRow = hiddenSheet.rowCount;
    
              // Store reference for data validation
              dropdownReferences[`${header.Field}-${rowIndex}`] = `HiddenSheet!$${String.fromCharCode(64 + col)}$${startRow}:$${String.fromCharCode(64 + col)}$${endRow}`;
            }
          });
        }
      });
    
      // Hide the hidden sheet
      hiddenSheet.state = "hidden";
    
      // Define headers for the main sheet
      mainSheet.columns = templateHeader.map((header , headerindex) => ({
        header: header.DisplayName,
        key: header.Field,
        width: (headerindex == 0 && Note) ? 60 : 30 
      }));
    
      // Add data rows to the main sheet
      rowData.forEach((row, rowIndex) => {
        const newRow: any = {};
        templateHeader.forEach((header) => {
          newRow[header.Field] = row[header.Field] || "";
        });
        const addedRow = mainSheet.addRow(newRow);
    
        // Apply dropdown validation for fields with options or optionsField
        templateHeader.forEach((header, colIndex) => {
          if (header.options || header.optionsField) {
            const key = `${header.Field}-${rowIndex}`;
            if (dropdownReferences[key]) {
              mainSheet.getCell(addedRow.number, colIndex + 1).dataValidation = {
                type: "list",
                allowBlank: true,
                formulae: [dropdownReferences[key]],
              };
            }
          }
        });
      });

   if(Note){
    mainSheet.getCell(`A${5+rowData.length}`).value = `${Note}`;
    mainSheet.getCell(`A${5+rowData.length}`).alignment = { wrapText: true };
   }
    
    
      // Save the workbook
      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buffer]), `${sheetName}.xlsx`);
    };
    
    


    return (
        <>
           <CustomButton
                type="reset"
                variant="text"
                startIcon={<CustomSvg  color={`${theme.palette.primary.main}`} size={"16px"} name={"export"} />}
                name={buttonName}
                onClick={() => generateExcel( header , Data )}
              />
          
        
        </>
    )
}

export default CommonDataTemplateSheet