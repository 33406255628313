import { AnyAction, ThunkDispatch, combineReducers, configureStore } from "@reduxjs/toolkit";
// import { createLogger} from 'redux-logger';
import authRedcuer from "./slices/authorization";
import vendorData from "./slices/vendorData";
import appLoader from "./slices/loader";
import menuSlice from "./slices/menu";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import tabsData from "./slices/tabsData";
import userData from "./slices/userData";
import roleData from "./slices/roleData";
import emailTemplateData from "./slices/EmailTemplateData";
import toasterData from "./slices/ToasterData";

import customFormFields from "./slices/customFormFields";
import changePassword from "./slices/chagepasswordslice";
import departmentData from "./slices/departmentData";
import assessmentCategoryMasterData from "./slices/assessmentCategoryMasterData";
import pageSettingSlice from "./slices/pageSettings";
import eventLogs from "./slices/eventLog";
import saveasdraft from "./slices/saveasdraft";
import defaultData from "./slices/defaultValues";
import makerChecker from "./slices/makerChecker";
import pendingApproval from "./slices/pendingApprovals";
import notification from "./slices/notification";
import assessmentQuestionnaireMaster from "./slices/assessmentQuestionnaireMaster";
import contentMasterData from "./slices/contentMasterData";
import industryMasterData from "./slices/industryMasterData";
import locationMasterData from "./slices/locationMasterData";
import lovMasterData from "./slices/lovMasterData";
import securityParameters from "./slices/securityParameters";
import listOfValuesData from "./slices/ListOfValues";
import documentMasterData from "./slices/documentMasterData";
import regionalSettingsData from "./slices/regionalSettingsData";
import thirdPartyMaster from "./slices/thirdPartyData";
import CurrencyMaster from "./slices/currencyData";
import branchMasterData from "./slices/branchMasterData";
import bulkuploadMaster from "./slices/bulkuploadMaster";
import serviceCatalog from "./slices/serviceCatalog";
import vendorDetails from "./slices/vendorDetails";
import recordLock from "./slices/recordLock";
import companyMasterData from "./slices/companyMasterData";
import workFlowSlice from "./slices/workFlowSlice";
import applicationParameter from "./slices/applicationParameterData";
import fileHandler from "./slices/fileHandler";
import assesmentQuestionnaireMapping from "./slices/assesmentQuestionnaireMapping";
import riskRateMasterData from "./slices/riskRateMasterData";
import signzySlice from "./slices/signzySlice";
import workFlowExecutionSlice from "./slices/workFlowExecutionslice";
import workFlowInstanceSlice from "./slices/workFlowInstanceSlice";
import vendorAssessmenDetails from "./slices/vendorAssessment";
import metricsMasters from "./slices/metricsMasters";
import rolesAndResponsibility from "./slices/rolesAndResponsiblity";
import workFlowHistoryData from "./slices/workFlowHistoryData";
import vendorSignOff from "./slices/vendorSignOff";
import pendingActions from "./slices/pendingAction";
import { createTransform, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import masterEditEnableData from "./slices/masterEditEnableData";
import renewalSlices from "./slices/renewalSlices";
import profilePicture from "./slices/profilePicture";
import FavouriteNavData from "./slices/FavouriteNavData";
import menuReducer from "./slices/MenuShow";
import clauseDataSlice from "./contractManagement/slices/clauseDataSlice";
import templateDataSlice from "./contractManagement/slices/templateData";
import holidayMasterData from "./slices/holidayMasterData";
import clauseMasterDataSlice from "./contractManagement/slices/clauseMasterSlice";
import contractMasterSlice from "./contractManagement/slices/contractMasterSlice";
import vendorConsentDataSlice from "./slices/consentFormData";
import contractListSlice from "./contractManagement/slices/contractListSlice";
import ProbeData from "./slices/ProbeData";
import textEditorSlice from "./slices/textEditorSlice";
import contractRolesAndResponsibilitySlice from "./contractManagement/slices/contractRolesAndResponsiblity";
import pluginUserSlice from "./contractManagement/slices/pluginUserRole";
import costApprovalEmailSlice from "./contractManagement/slices/costApprovalSliceEmail";
import CostApprovalMetricSlice from "./contractManagement/slices/costApprovalMetricSlice";
import CostApprovalSlice from "./contractManagement/slices/CostApprovalSlice";
import pluginDataSlice from "./contractManagement/slices/pluginDataSlice";
import editorDocSlice from "./slices/docsIdData";
import senForVendorReview from "./contractManagement/slices/sendForVendorReview";
import CustomerAssessmentDetails from "./slices/cobAssessment";
import customerSignOffData from "./slices/customerSignoff";
import apiMasterData from './slices/apiMaster';
import assesmentQuestionnaireMappingCOB from "./slices/assessmentQuestionnaireMappingCOB";
import formTitleMasterData from "./slices/formTitleMasterData";
import ApiCatalog from "../components/apiCatalogue/ApiCatalog";
import ApiCatalogData from "./slices/apiCatalog";
import PhysicalUploadSlice from "./contractManagement/slices/physicalUploadSlice";
import ArticleMasterSlice from "./contractManagement/slices/articleMasterSlice";
import SeverityMasterDataSlice from "./performanceManagement/slices/severityMaster";
import DeviationToleranceLevelMasterDataSlice from "./performanceManagement/slices/deviationToldeviationToleranceLevelMaster";
import ThresholdMasterDataSlice from "./performanceManagement/slices/thresholdMaster";
import ContractFileHandlerSlice from "./contractManagement/slices/contractFileHandler";
import  PMScopeData  from "./performanceManagement/slices/defineScope";
import PMContractListSlice from "./contractManagement/slices/PMContractListSlice";
import  PMEventLogData  from "./performanceManagement/slices/pmEventLog";
import  PmRandRReviewDataSlice  from "./performanceManagement/slices/pmRandRReview";
// const logMiddleWare = createLogger({
//     predicate: ()=> process.env.NODE_ENV === 'development'
// });

// const logger = ({ getState }:any) => (next: (arg0: any) => void) => (action: any) => {
//     console.log(getState() + " ...before");
//     next(action);
//     return action;
//   };

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["loggerData"], // Add reducers you want to persist
};
export const performanceManagementReducer: any = combineReducers({
  SeverityMasterDataSlice: SeverityMasterDataSlice,
  DeviationToleranceLevelMasterDataSlice: DeviationToleranceLevelMasterDataSlice,
  ThresholdMasterDataSlice: ThresholdMasterDataSlice,
  PMScopeDataSlice : PMScopeData, 
  PMEventLogDataSlice : PMEventLogData,
  PmRandRReviewDataSlice:PmRandRReviewDataSlice,
});
export const contractManagementReducer: any = combineReducers({
  clauseData: clauseDataSlice,
  templateData: templateDataSlice,
  clauseMasterData: clauseMasterDataSlice,
  contractMasterData: contractMasterSlice,
  contractlist: contractListSlice,
  contractRolesAndResponsibility: contractRolesAndResponsibilitySlice,
  textEditor: textEditorSlice,
  pluginLoginUserRole: pluginUserSlice,
  pluginData: pluginDataSlice,
  editorDoc: editorDocSlice,
  costApprovalEmail: costApprovalEmailSlice,
  costApprovalMetric: CostApprovalMetricSlice,
  costApproval: CostApprovalSlice,
  sendForVendorReview: senForVendorReview,
  physicalUploadData: PhysicalUploadSlice,
  articleMasterData: ArticleMasterSlice,
  contractFileHandler:ContractFileHandlerSlice,
  PMContractListSlice:PMContractListSlice,
});
const appReducer = combineReducers({
  loggerData: authRedcuer,
  vendorData: vendorData,
  tabData: tabsData,
  appLoader: appLoader,
  menu: menuSlice,
  userData: userData,
  roleData: roleData,
  emailTemplateData: emailTemplateData,
  toasterData: toasterData,
  customFormFields: customFormFields,
  changePassword: changePassword,
  departmentData: departmentData,
  assessmentCategoryMasterData: assessmentCategoryMasterData,
  indutryMasterData: industryMasterData,
  HolidayMasterData: holidayMasterData,
  FormTitleMasterData: formTitleMasterData,
  locationMasterData: locationMasterData,
  lovMasterData: lovMasterData,
  WorkFlowHistoryData: workFlowHistoryData,
  listOfValues: listOfValuesData,
  DocumentMasterData: documentMasterData,
  RegionalSettingsData: regionalSettingsData,
  BranchMasterData: branchMasterData,
  CompanyMasterData: companyMasterData,
  pageSettings: pageSettingSlice,
  eventLogs: eventLogs,
  saveAsDraft: saveasdraft,
  defaultData: defaultData,
  makerChecker: makerChecker,
  pendingApproval: pendingApproval,
  notification: notification,
  recordLock: recordLock,
  securityParameters: securityParameters,
  aQMData: assessmentQuestionnaireMaster,
  vendorAssessmentData: vendorAssessmenDetails,
  aQMMappingData: assesmentQuestionnaireMapping,
  contentData: contentMasterData,
  vendorConsentData: vendorConsentDataSlice,
  thirdPartyData: thirdPartyMaster,
  CurrencyData: CurrencyMaster,
  RiskRateData: riskRateMasterData,
  MasterEditEnableData: masterEditEnableData,
  serviceCatalog: serviceCatalog,
  vendorDetails,
  bulkuploadData: bulkuploadMaster,
  workFlow: workFlowSlice,
  applicationParameter: applicationParameter,
  fileHandler: fileHandler,
  signzySlice: signzySlice,
  workFlowExection: workFlowExecutionSlice,
  metricsMasters: metricsMasters,
  workFlowInstanceSlice: workFlowInstanceSlice,
  rolesAndResponsibilityData: rolesAndResponsibility,
  vendorSignOff: vendorSignOff,
  pendingActions: pendingActions,
  renewal: renewalSlices,
  profilePicture: profilePicture,
  FavouriteNavData: FavouriteNavData,
  menuShown: menuReducer,
  ProbeData: ProbeData,
  CustomerAssessmentData: CustomerAssessmentDetails,
  CustomerSignoffData: customerSignOffData,
  assesmentQuestionnaireMappingCOBData: assesmentQuestionnaireMappingCOB,
  contractManagementReducer: contractManagementReducer,
  ApiMasterData: apiMasterData,
  ApiCatalogData: ApiCatalogData,
  performanceManagementReducer: performanceManagementReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

// export const store = configureStore({
//   reducer:rootReducer ,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: false,
//     }),
//   devTools: process.env.NODE_ENV !== "production",
//   // devTools: false,
// });

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatchThunk = ThunkDispatch<RootState, void, AnyAction>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatchThunk = () => useDispatch<AppDispatchThunk>();

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
